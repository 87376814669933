import * as React from 'react';
import { connect } from 'react-redux';
import { Router, Redirect, RouteComponentProps } from '@reach/router';

import styles from './DefaultLayout.module.css';

import { closeDialog } from '../../actions/dialog';
import BaseDialog from '../../components/BaseDialog';
import TopBarContainer from './TopBarContainer';
import Navigation from '../../components/Navigation';
import { OrganizationRole } from '../../types/user';

const ViewMap = React.lazy(() =>
  import(/* webpackChunkName: "Map" */ '../../components/views/ViewMap')
);

const ViewChangePassword = React.lazy(() =>
  import(
    /* webpackChunkName: "ChangePassword" */ '../../components/views/ViewChangePassword'
  )
);

const ViewDashboard = React.lazy(() =>
  import(
    /* webpackChunkName: "Dashboard" */ '../../components/views/ViewDashboard'
  )
);

const ViewSiteManagerSites = React.lazy(() =>
  import(
    /* webpackChunkName: "SiteManagerSites" */ '../../components/views/ViewSiteManagerSites'
  )
);

const ViewRegionalManagerSites = React.lazy(() =>
  import(
    /* webpackChunkName: "RegionalManagerSites" */ '../../components/views/ViewRegionalManagerSites'
  )
);

const ViewRegionalNotifications = React.lazy(() =>
  import(
    /* webpackChunkName: "ViewRegionalNotifications" */ '../../components/views/ViewRegionalNotifications'
  )
);

type Props = {
  isInitialised: boolean;
  userIsInitialized: boolean;
  organizationRole?: OrganizationRole;
  currentUserSiteId?: number;
  dialog: any;
  closeDialog: any;
} & RouteComponentProps;

function DefaultLayout({
  isInitialised,
  userIsInitialized,
  organizationRole,
  currentUserSiteId,
  dialog,
  closeDialog,
  location,
}: Props) {
  if (isInitialised === false || userIsInitialized === false) {
    return null;
  }
  const noNavigation = location!.pathname.includes('report');
  return (
    <div className={noNavigation ? '' : styles.root}>
      <Router primary={false}>
        <Navigation
          path="*"
          organizationRole={organizationRole}
          siteId={currentUserSiteId}
          noNavigation={noNavigation}
        />
      </Router>
      <main className={styles.content}>
        <TopBarContainer noNavigation={noNavigation} />
        <React.Suspense fallback={<div>Loading...</div>}>
          <Router primary={false} className={styles.fullFrame}>
            <ViewChangePassword path="settings/change-password" />
            <RoleRoutes
              path="*"
              siteId={currentUserSiteId}
              organizationRole={organizationRole}
            />
          </Router>
        </React.Suspense>
      </main>
      <BaseDialog dialog={dialog} onClose={closeDialog} />
    </div>
  );
}

const RoleRoutes = ({ siteId, organizationRole }: any) => {
  if (organizationRole === OrganizationRole.SITE_MANAGER) {
    return (
      <Router primary={false} style={{ height: '100%', overflow: 'auto' }}>
        <ViewMap path="map/*" />
        <ViewSiteManagerSites path="sites/*" siteId={siteId} />
        <Redirect from="/" to={`/sites/${siteId}`} noThrow />
        <Redirect
          from="/notifications/*"
          to={`/sites/${siteId}/notifications/`}
          noThrow
        />
      </Router>
    );
  } else if (organizationRole === OrganizationRole.REGIONAL_MANAGER) {
    return (
      <Router primary={false} style={{ height: '100%' }}>
        <ViewMap path="map/*" />
        <ViewRegionalManagerSites path="sites/*" />
        <ViewRegionalNotifications path="notifications/*" />
        <ViewDashboard path="dashboard/*" />
        <Redirect from="/" to="/notifications/overview" noThrow />
      </Router>
    );
  }
  return null;
};

const mapStateToProps = state => ({
  isInitialised: state.app.isInitialised,
  userIsInitialized: !!state.app.userIsInitialized,
  organizationRole: state.app.organizationRole,
  currentUserSiteId: state.app.currentUserSiteId,
  dialog: state.dialog,
});

const mapDispatchToProps = { closeDialog };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DefaultLayout);
