import React, { useEffect } from 'react';
import styles from './TopBarContainer.module.css';
import { navigate } from '@reach/router';
import { connect } from 'react-redux';

import TopBar from '@energybox/react-ui/core/TopBar';
import TopBarMenu from '@energybox/react-ui/core/TopBarMenu';
import TopBarMenuItem from '@energybox/react-ui/core/TopBarMenuItem';
import { User as UserIcon } from '@energybox/react-ui/core/Icons';
import { Organization as OrganizationIcon } from '@energybox/react-ui/core/Icons';

import { logout } from '../../actions/app';
import { CurrentUser } from '../../reducers/app';
import { CurrentOrganization } from '../../types/organization';
import { getCurrentOrganization } from '../../actions/organizations';

const ConnectedTopBar = ({
  user,
  logout,
  noNavigation,
  currentOrganization,
  getCurrentOrganization,
}: {
  user?: CurrentUser;
  logout: () => void;
  noNavigation?: boolean;
  currentOrganization?: CurrentOrganization;
  getCurrentOrganization: () => void;
}) => {
  useEffect(() => {
    getCurrentOrganization();
  }, [getCurrentOrganization]);

  if (noNavigation) return <div />;

  return (
    <TopBar>
      <div />
      <div>
        {user && (
          <div className={styles.organizationMenuContainer}>
            <OrganizationIcon color="var(--accent-base)" size={14} />
            <span className={styles.organizationMenuTitle}>
              {currentOrganization ? currentOrganization.title : '-'}
            </span>
          </div>
        )}

        <div className={styles.userMenuContainer}>
          <TopBarMenu
            title={user ? `${user.firstName} ${user.lastName}` : '-'}
            icon={
              <UserIcon variant="tiny" color="var(--accent-base)" size={16} />
            }
          >
            <TopBarMenuItem
              className={styles.link}
              onSelect={() => navigate('/settings/change-password')}
            >
              Change Password
            </TopBarMenuItem>
            <TopBarMenuItem className={styles.link} onSelect={() => logout()}>
              Logout
            </TopBarMenuItem>
          </TopBarMenu>
        </div>
      </div>
    </TopBar>
  );
};

const mapStateToProps = ({ app, organizations }) => ({
  user: app.currentUser,
  currentOrganization: organizations.currentOrganization,
});

const mapDispatchToProps = {
  logout,
  getCurrentOrganization,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedTopBar);
