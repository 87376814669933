import { fetchApi } from '../middleware/apiMiddleware';
import { Site, SitesApiFilter } from '../types/site';

export enum Actions {
  SITES_SUCCESS = '@@app/SITES_SUCCESS',
  SITES_LOADING = '@@app/SITES_LOADING',
  SITES_ERROR = '@@app/SITES_ERROR',

  SITE_SUCCESS = '@@app/SITES_SUCCESS',
  SITE_LOADING = '@@app/SITES_LOADING',
  SITE_ERROR = '@@app/SITES_ERROR',

  GET_SITE_ONLINE_STATUS_SUCCESS = '@sites/GET_SITE_ONLINE_STATUS_SUCCESS',
  GET_SITE_ONLINE_STATUS_ERROR = '@sites/GET_SITE_ONLINE_STATUS_ERROR',
  GET_SITE_ONLINE_STATUS_LOADING = '@sites/GET_SITE_ONLINE_STATUS_LOADING',
}

export const fetchSiteStatus = site => {
  // @ts-ignore
  return fetchApi({ endpoint: `/api/v1/sites/${site.id}/online-status` });
};

export const getSiteOnlineStatus = (id: number) => ({
  type: 'API_GET',
  path: `/api/v1/sites/${id}/online-status`,
  success: { type: Actions.GET_SITE_ONLINE_STATUS_SUCCESS, id },
  loading: { type: Actions.GET_SITE_ONLINE_STATUS_LOADING, id },
  error: { type: Actions.GET_SITE_ONLINE_STATUS_ERROR, id },
});

export const setSitesFilter = (filter?: SitesApiFilter): string => {
  const queryParams = new URLSearchParams();

  if (filter && filter.equipmentIds) {
    queryParams.set('equipmentIds', filter.equipmentIds.join(','));
  }

  if (filter && filter.sensorIds) {
    queryParams.set('sensorIds', filter.sensorIds.join(','));
  }

  if (filter && filter.spaceIds) {
    queryParams.set('spaceIds', filter.spaceIds.join(','));
  }

  if (filter && filter.sensorTypes && filter.sensorTypes.length > 0) {
    queryParams.set('sensorTypes', filter.sensorTypes.join(','));
  }

  if (filter && filter.sensorTarget) {
    queryParams.set('sensorTarget', filter.sensorTarget);
  }

  return `/api/v1/sites?${queryParams.toString()}`;
};

export const fetchSites = (filter?: SitesApiFilter): Promise<Site[]> =>
  fetchApi({ endpoint: setSitesFilter(filter) });

export const getSitesByTargets = (targetIds: number[] = []): Promise<Site[]> =>
  fetchApi({
    endpoint: `/api/v1/sites/by-targets?targetIds=${targetIds.join(',')}`,
  });

export const getSites = (filter?: SitesApiFilter) => ({
  type: 'API_GET',
  path: setSitesFilter(filter),
  loading: Actions.SITES_LOADING,
  success: Actions.SITES_SUCCESS,
  error: Actions.SITES_ERROR,
});

export const fetchSiteById = (siteId: string | number): Promise<Site> =>
  fetchApi({ endpoint: `/api/v1/sites/${siteId}` }).catch(err => err);

export const fetchSiteByResourceId = (resourceId: number): Promise<Site> =>
  fetchApi({
    endpoint: `/api/v1/resources/${resourceId.toString()}/site`,
  });

export const setSites = (sites: Site[]) => ({
  type: Actions.SITES_SUCCESS,
  payload: sites,
});
