export type Dialog = {
  isOpen: boolean;
  title?: string;
  content?: string;
  actionFn?: any;
  targetId?: string;
  type?: string;
};

export type FnDialogOpen = (dialog: Dialog) => void;

export enum DialogTypes {
  DEFAULT = 'DEFAULT',
  DISMISS_NOTIFICATION = 'DISMISS_NOTIFICATION',
}
