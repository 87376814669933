import { Equipment } from '../types/equipment';
import { Actions } from '../actions/equipment';

const initialState: Equipment[] = [];

export default (state: Equipment[] = initialState, action: any) => {
  switch (action.type) {
    case Actions.EQUIPMENT_SUCCESS: {
      // TODO: Make separate action for equipment by site id?

      if (Array.isArray(action.payload)) return [...action.payload];
      else return [...action.payload.equipment];
    }

    case Actions.EQUIPMENT_BY_ID_SUCCESS: {
      const index = state.findIndex(e => e.id === action.payload.id);

      return [
        ...state.slice(0, index),
        { ...action.payload, hasFullPath: true },
        ...state.slice(index + 1),
      ];
    }

    default: {
      return state;
    }
  }
};
