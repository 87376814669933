import { fetchApi } from '../middleware/apiMiddleware';
import { Gateway, GatewayApiFilter } from '../types/gateway';

export enum Actions {
  GATEWAYS_SUCCESS = '@@app/GATEWAYS_SUCCESS',
  GATEWAYS_LOADING = '@@app/GATEWAYS_LOADING',
  GATEWAYS_ERROR = '@@app/GATEWAYS_ERROR',
}

export const fetchGateways = (
  filter?: GatewayApiFilter
): Promise<Gateway[]> => {
  const queryParams = new URLSearchParams();

  if (filter && filter.limit) {
    queryParams.set('limit', filter.limit.toString());
  }

  if (filter && filter.siteIds && filter.siteIds.length > 0) {
    queryParams.set('siteIds', filter.siteIds.join(','));
  }

  if (filter && filter.withPath) {
    queryParams.set('withPath', 'true');
  }

  return fetchApi({
    endpoint: `/api/v1/gateways?${queryParams.toString()}`,
  });
};

export const fetchGatewaysByResource = (
  resourceId: number,
  recursive: boolean = false
) =>
  fetchApi({
    endpoint: `/api/v1/gateways/resource/${resourceId.toString()}?recursive=${recursive.toString()}`,
  });

export function getGatewaysBySiteId(siteId: number) {
  return dispatch => {
    dispatch({ type: Actions.GATEWAYS_LOADING });
    return fetchGatewaysByResource(siteId)
      .then(gateways => {
        dispatch({
          type: Actions.GATEWAYS_SUCCESS,
          payload: { siteId, gateways },
        });
        return { gateways };
      })
      .catch(error => {
        dispatch({ type: Actions.GATEWAYS_ERROR, error });
        return { error };
      });
  };
}
