import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, navigate } from '@reach/router';
import { OrganizationRole } from '../types/user';
import { setCurrentSiteId } from '../actions/app';
import { clearNotifications } from '../actions/notifications';

import SwitchIcon from '@energybox/react-ui/core/Icons/Switch';
import DashboardIcon from '@energybox/react-ui/core/Icons/Dashboard';
import NotificationIcon from '@energybox/react-ui/core/Icons/Notification';
import NotificationSolidIcon from '@energybox/react-ui/core/Icons/NotificationSolid';
import DashboardSolidIcon from '@energybox/react-ui/core/Icons/DashboardSolid';
import EquipmentSolidIcon from '@energybox/react-ui/core/Icons/EquipmentSolid';
import EquipmentIcon from '@energybox/react-ui/core/Icons/Equipment';
import SitesIcon from '@energybox/react-ui/core/Icons/Sites';

import withAccessToken from '../containers/auth/withAccessToken';
import { ApplicationMenu, ApplicationMenuItem } from '@energybox/react-ui';

type Props = {
  path?: string;
  location?: any;
  organizationRole?: OrganizationRole;
  siteId?: number;
  setCurrentSiteId: (siteId: number | undefined) => void;
  clearNotifications: () => void;
  noNavigation?: boolean;
};

const SwitchLinkWithToken = withAccessToken(({ accessToken }) => (
  <a
    href={`${
      process.env.REACT_APP_PLATFORM_SWITCH_URL
    }/#accessToken=${accessToken}`}
  >
    <ApplicationMenuItem
      variant="highlighted"
      icon={<SwitchIcon size={30} />}
      title="To Setup"
    />
  </a>
));

function Navigation({
  location,
  organizationRole,
  siteId,
  setCurrentSiteId,
  clearNotifications,
  noNavigation,
}: Props) {
  const currentPath = location ? location.pathname : '';
  if (!organizationRole) return <ApplicationMenu initiallyOpen />;
  if (noNavigation) return <div />;
  return (
    <ApplicationMenu
      initiallyOpen
      title=""
      logoOnClick={() => {
        setCurrentSiteId(undefined);
        navigate('/notifications/overview');
      }}
    >
      <div>
        <RoleNavigation
          organizationRole={organizationRole}
          currentPath={currentPath}
          siteId={siteId}
          setCurrentSiteId={setCurrentSiteId}
          clearNotifications={clearNotifications}
          noNavigation={noNavigation}
        />
      </div>
      <div>
        <SwitchLinkWithToken />
      </div>
    </ApplicationMenu>
  );
}

const RoleNavigation = ({
  organizationRole,
  currentPath,
  siteId,
  setCurrentSiteId,
  clearNotifications,
  noNavigation,
}) => {
  const [urlSiteId, setUrlSiteId] = useState(null);

  useEffect(() => {
    if (currentPath.match('/sites/')) {
      const id = currentPath.split('/').find(str => {
        return str.match(/^-{0,1}\d+$/);
      });
      if (id) setUrlSiteId(id);
    } else {
      setUrlSiteId(null);
    }
  }, [currentPath]);

  // if (noNavigation) return <div />;
  if (organizationRole === OrganizationRole.REGIONAL_MANAGER) {
    return (
      <>
        <div
          onClick={() => {
            setCurrentSiteId(undefined);
            clearNotifications();
          }}
        >
          <Link to={'/notifications/overview'}>
            <ApplicationMenuItem
              isActive={!!currentPath.match(/^\/notifications/)}
              icon={<NotificationIcon size={30} />}
              title="Regional Notifications"
            />
          </Link>
        </div>
        <Link to={'/dashboard'}>
          <ApplicationMenuItem
            isActive={!!currentPath.match('/dashboard')}
            icon={<DashboardIcon size={30} />}
            title={
              <span>
                Regional <br /> Insights
              </span>
            }
          />
        </Link>

        <div
          onClick={() => {
            setCurrentSiteId(undefined);
          }}
        >
          <Link to={'/sites'}>
            <ApplicationMenuItem
              isActive={
                !!currentPath.match(/^\/sites$/) ||
                !!currentPath.match(/^\/map$/)
              }
              icon={<SitesIcon size={30} />}
              title="Sites"
            />
          </Link>
        </div>

        {urlSiteId && (
          <>
            <div
              style={{
                padding: '1rem 0 0.5rem 0',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <div
                style={{
                  border: 'solid thin white',
                  width: '40%',
                }}
              />
            </div>
            <div
              onClick={() => {
                clearNotifications();
              }}
            >
              <Link to={`sites/${urlSiteId}/notifications/overview`}>
                <ApplicationMenuItem
                  isActive={
                    !!currentPath.match(`sites/${urlSiteId}/notifications`)
                  }
                  icon={<NotificationSolidIcon size={30} />}
                  title={
                    <span>
                      Site <br /> Notifications
                    </span>
                  }
                />
              </Link>
            </div>

            <Link to={`/sites/${urlSiteId}`}>
              <ApplicationMenuItem
                isActive={currentPath === `/sites/${urlSiteId}`}
                icon={<DashboardSolidIcon size={30} />}
                title={
                  <span>
                    Site <br /> Insights
                  </span>
                }
              />
            </Link>

            <Link to={`/sites/${!!urlSiteId ? urlSiteId : siteId}/equipment`}>
              <ApplicationMenuItem
                isActive={!!currentPath.match(`/equipment`)}
                icon={<EquipmentSolidIcon size={30} />}
                title={
                  <span>
                    Site <br /> Equipment
                  </span>
                }
              />
            </Link>
          </>
        )}
      </>
    );
  } else if (organizationRole === OrganizationRole.SITE_MANAGER) {
    return (
      <>
        <Link to={`/sites/${siteId}/notifications/overview`}>
          <ApplicationMenuItem
            isActive={!!currentPath.match(`sites/${urlSiteId}/notifications`)}
            icon={<NotificationIcon size={30} />}
            title={`Site Notifications`}
          />
        </Link>
        <Link to={`/sites/${siteId}`}>
          <ApplicationMenuItem
            isActive={currentPath === `/sites/${urlSiteId}`}
            icon={<DashboardIcon size={30} />}
            title="Site Insights"
          />
        </Link>
        <Link to={'/sites'}>
          <ApplicationMenuItem
            isActive={
              !!currentPath.match(/^\/sites$/) || !!currentPath.match(/^\/map$/)
            }
            icon={<SitesIcon size={30} />}
            title="Sites"
          />
        </Link>
        <Link to={`/sites/${siteId}/equipment`}>
          <ApplicationMenuItem
            isActive={!!currentPath.match('/equipment')}
            icon={<EquipmentIcon size={30} />}
            title="Site Equipment"
          />
        </Link>
      </>
    );
  }
  return null;
};

function mapStateToProps(state) {
  return {};
}

export default connect(
  mapStateToProps,
  { setCurrentSiteId, clearNotifications }
)(Navigation);
