//TODO: refactor and use ramda for rest of reducer
import * as R from 'ramda';
import { Notification } from '../types/notification';
import { Actions } from '../actions/notifications';
import { SentinelPriorityLevel } from '../types/sentinel';

const initialState = {
  isLoading: false,
  notifications: [],
  notificationsBySensorId: {},
  notificationsByFeatureNotificationId: {},
};

interface Notifications {
  isLoading: boolean;
  notifications: Notification[];
  notificationsBySensorId: NotificationsById;
  notificationsByFeatureNotificationId: NotificationsById;
}

export type NotificationsById = {
  [id: string]: NotificationById;
};

export type NotificationById = {
  isLoading: boolean;
  notifications: Notification[];
};

function reverseHandlingLogs(notification: Notification): Notification {
  const { handlingLogs, ...rest } = notification;
  return {
    ...rest,
    handlingLogs: [...handlingLogs].reverse(),
  };
}

export function sortByDateDescending(a: Notification, b: Notification): number {
  return (
    new Date(b.handlingLogs[0].at).valueOf() -
    new Date(a.handlingLogs[0].at).valueOf()
  );
}

function sortByDateByUnseen(a: Notification, b: Notification): number {
  if (a.dismissedBy) {
    if (b.dismissedBy) {
      return sortByDateDescending(a, b);
    }
    return 1;
  } else if (b.dismissedBy) {
    return -1;
  }
  return sortByDateDescending(a, b);
}

const priorityMap = {
  [SentinelPriorityLevel.NORMAL]: 1,
  [SentinelPriorityLevel.MEDIUM]: 2,
  [SentinelPriorityLevel.HIGH]: 3,
  [SentinelPriorityLevel.HIGHEST]: 4,
};

function sortByPriority(a: Notification, b: Notification): number {
  const aPrio =
    priorityMap[a.handlingLogs[0] && a.handlingLogs[0].priorityLevel] || 0;
  const bPrio =
    priorityMap[b.handlingLogs[0] && b.handlingLogs[0].priorityLevel] || 0;

  return bPrio - aPrio;
}

function sortByPriorityDateDescending(
  a: Notification,
  b: Notification
): number {
  return sortByPriority(a, b) === 0
    ? sortByDateDescending(a, b)
    : sortByPriority(a, b);
}

export function sortByPriorityDateUnseen(
  a: Notification,
  b: Notification
): number {
  if (a.dismissedBy) {
    if (b.dismissedBy) {
      return sortByPriorityDateDescending(a, b);
    }
    return 1;
  } else if (b.dismissedBy) {
    return -1;
  }
  return sortByPriorityDateDescending(a, b);
}

const notifications = (state: Notifications = initialState, action: any) => {
  switch (action.type) {
    case Actions.NOTIFICATIONS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        notifications: action.payload
          .map(n => reverseHandlingLogs(n))
          .sort(sortByDateDescending),
      };
    }

    case Actions.NOTIFICATIONS_LOADING: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case Actions.DISMISS_SUCCESS: {
      const notificationIndex = state.notifications.findIndex(
        e => e.id === action.payload.id
      );

      return {
        ...state,
        isLoading: false,
        notifications: [
          ...state.notifications.slice(0, notificationIndex),
          reverseHandlingLogs(action.payload),
          ...state.notifications.slice(notificationIndex + 1),
        ],
      };
    }

    case Actions.DISMISS_LOADING: {
      return {
        ...state,
        isLoading: true,
      };
    }

    // TODO: error handling across Pulse app
    case Actions.DISMISS_ERROR: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case Actions.NOTIFICATIONS_CLEAR: {
      return initialState;
    }

    case Actions.ORDER_UNSEEN: {
      return {
        ...state,
        notifications: [...state.notifications].sort(sortByDateByUnseen),
      };
    }

    case Actions.ORDER_DATE: {
      return {
        ...state,
        notifications: [...state.notifications].sort(sortByDateDescending),
      };
    }

    case Actions.GET_NOTIFICATIONS_BY_SENSOR_ID_LOADING: {
      return R.assocPath(
        action.notificationId
          ? [
              'notificationsByFeatureNotificationId',
              action.notificationId,
              'isLoading',
            ]
          : ['notificationsBySensorId', action.sensorId, 'isLoading'],
        true,
        state
      );
    }

    case Actions.GET_NOTIFICATIONS_BY_SENSOR_ID_SUCCESS: {
      return R.pipe(
        R.assocPath(
          action.notificationId
            ? [
                'notificationsByFeatureNotificationId',
                action.notificationId,
                'notifications',
              ]
            : ['notificationsBySensorId', action.sensorId, 'notifications'],
          action.payload
            .map(n => reverseHandlingLogs(n))
            .sort(sortByDateDescending)
        ),
        R.assocPath(
          ['notificationsBySensorId', action.sensorId, 'isLoading'],
          false
        )
      )(state);
    }

    default: {
      return state;
    }
  }
};

export default notifications;
