import { Actions } from '../actions/sites';

export interface SiteOnlineStatusById {
  [key: number]: string;
}

const initialState = {};

export default function siteOnlineStatusById(
  state: SiteOnlineStatusById = initialState,
  action: any
) {
  switch (action.type) {
    case Actions.GET_SITE_ONLINE_STATUS_SUCCESS: {
      return {
        ...state,
        [action.id]: action.payload,
      };
    }
  }
  return state;
}
