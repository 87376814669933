import { fetchApi } from '../middleware/apiMiddleware';
import { Sensor, SensorApiFilter } from '../types/sensors';
import { Service } from '../config';
import format from 'date-fns/format';
const apiBase = '/api/v1/sensors';

export enum Actions {
  BY_ID_LOADING = '@@sensors/BY_ID_LOADING',
  BY_ID_SUCCESS = '@@sensors/BY_ID_SUCCESS',
  BY_ID_ERROR = '@@sensors/BY_ID_ERRORS',

  SENSORS_SUCCESS = '@@app/SENSORS_SUCCESS',
  SENSORS_LOADING = '@@app/SENSORS_LOADING',
  SENSORS_ERROR = '@@app/SENSORS_ERROR',

  GET_SENSORS_BY_EQUIPMENT_ID_SUCCESS = '@@app/GET_SENSORS_BY_EQUIPMENT_ID_SUCCESS',
  GET_SENSORS_BY_EQUIPMENT_ID_LOADING = '@@app/GET_SENSORS_BY_EQUIPMENT_ID_LOADING',
  GET_SENSORS_BY_EQUIPMENT_ID_ERROR = '@@app/GET_SENSORS_BY_EQUIPMENT_ID_ERROR',
}

const setSensorFilter = (filter?: SensorApiFilter): string => {
  const queryParams = new URLSearchParams();

  if (filter && filter.limit) {
    queryParams.set('limit', filter.limit.toString());
  }

  if (filter && filter.siteIds && filter.siteIds.length > 0) {
    queryParams.set('siteIds', filter.siteIds.join(','));
  }

  if (filter && filter.equipmentIds && filter.equipmentIds.length > 0) {
    queryParams.set('equipmentIds', filter.equipmentIds.join(','));
  }

  if (filter && filter.sensorTypes && filter.sensorTypes.length > 0) {
    queryParams.set('sensorTypes', filter.sensorTypes.join(','));
  }

  if (filter && filter.withPath) {
    queryParams.set('withPath', 'true');
  }

  return queryParams.toString();
};

export const fetchSensors = (filter?: SensorApiFilter): Promise<Sensor[]> => {
  return fetchApi({
    endpoint: `${apiBase}?${setSensorFilter(filter)}`,
  });
};

export const getSensorsByEquipmentId = (equipmentId: number) => ({
  type: 'API_GET',
  path: `${apiBase}?${setSensorFilter({ equipmentIds: [equipmentId] })}`,
  success: {
    type: Actions.GET_SENSORS_BY_EQUIPMENT_ID_SUCCESS,
    equipmentId,
  },
  error: Actions.GET_SENSORS_BY_EQUIPMENT_ID_ERROR,
  loading: Actions.GET_SENSORS_BY_EQUIPMENT_ID_LOADING,
});

export const fetchSensorsByResource = (
  resourceId: number,
  recursive: boolean = false
) =>
  fetchApi({
    endpoint: `${apiBase}/resource/${resourceId.toString()}?recursive=${recursive.toString()}`,
  });

export const fetchSensorById = (sensorId: number): Promise<Sensor> =>
  fetchApi({
    endpoint: `${apiBase}/${sensorId}`,
  });

export const getSensorById = (sensorId: number) => async (
  dispatch: any
): Promise<void> => {
  dispatch({ type: Actions.BY_ID_LOADING });

  try {
    const sensor = await fetchSensorById(sensorId);

    // sensor.hasFullPath = true;

    dispatch({ type: Actions.BY_ID_SUCCESS, payload: sensor });
    return Promise.resolve();
  } catch (err) {
    dispatch({ type: Actions.BY_ID_ERROR });
    return Promise.resolve();
  }
};

export const getSensorsBySiteId = (siteId: number) => dispatch => {
  dispatch({ type: Actions.SENSORS_LOADING });
  return fetchSensorsByResource(siteId, true)
    .then(sensors => {
      dispatch({ type: Actions.SENSORS_SUCCESS, payload: { sensors, siteId } });
      return { sensors };
    })
    .catch(error => {
      dispatch({ type: Actions.SENSORS_ERROR, payload: { error } });
      return { error };
    });
};

// NOTE: Not sure if these should go to a reducer? Or do we already have a way of doing all of this?

export const getDoorEntriesOverTimeBySensorId = (
  sensorId: number,
  fromTimeStamp: number,
  toTimeStamp: number
): Promise<any> => {
  return fetchApi({
    endpoint: `/v3/operational_sensors?filter[sensor]=${sensorId}&filter[from]=${fromTimeStamp}&filter[to]=${toTimeStamp}`,
    service: Service.tsd,
  })
    .then((readings: any) => {
      const entries = readings.data
        .filter(reading => reading.attributes.state[0] === true)
        .map(reading => ({
          ...reading,
          time: new Date(reading.attributes.timestamp * 1000).getHours(),
          day: format(new Date(reading.attributes.timestamp * 1000), 'iii'),
        }));
      return Promise.resolve(entries);
    })
    .catch(err => {
      return Promise.resolve(err);
    });
};
