import { Actions } from '../actions/spaces';
import { Space } from '../types/space';

interface SpacesBySiteId {
  [key: number]: Space[];
}

const initialState: SpacesBySiteId = {};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case Actions.SPACES_SUCCESS: {
      const { siteId, spaces } = action.payload;
      if (!siteId) return state;
      return {
        ...state,
        [siteId]: spaces,
      };
    }

    default:
      return state;
  }
};
