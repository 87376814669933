import * as R from 'ramda';
import { Actions as EnergyActions } from '../actions/energy';
import { Energy } from '../types/energy';

const initialState: Energy = {
  energyBySiteEquipmentGroup: {},
  energyByEquipmentId: {},
  energyBySiteEquipmentType: {},
  isLoading: false,
};

export default (state: Energy = initialState, action: any) => {
  switch (action.type) {
    case EnergyActions.ENERGY_BY_SITE_EQUIPMENT_TYPE_SUCCESS:
      return R.assoc('energyBySiteEquipmentType', action.payload, state);
    case EnergyActions.ENERGY_BY_SITE_EQUIPMENT_GROUP_SUCCESS:
      return R.assoc('energyBySiteEquipmentGroup', action.payload, state);

    case EnergyActions.ENERGY_BY_EQUIPMENT_ID_SUCCESS:
      return R.pipe(
        R.assoc('isLoading', false),
        R.assocPath(
          ['energyByEquipmentId', action.payload.resourceId],
          action.payload
        )
      )(state);

    case EnergyActions.ENERGY_BY_EQUIPMENT_ID_LOADING:
      return R.assoc('isLoading', true, state);

    //TODO: error handling
    case EnergyActions.ENERGY_BY_EQUIPMENT_ID_ERROR:
      return R.assoc('isLoading', false, state);

    default:
      return state;
  }
};
