import { Site } from '../types/site';
import { Actions } from '../actions/sites';

const initialState: Site[] = [];

export default (state: Site[] = initialState, action: any) => {
  switch (action.type) {
    case Actions.SITES_SUCCESS: {
      return [...action.payload].map((site, index) => ({
        ...site,
        latitude: site.latitude || 0,
        longitude: site.longitude || 0,
        hasAlert: Math.random() < 0.1 || index === 0,
      }));
    }

    case Actions.SITE_SUCCESS:
      return [...state.filter(s => s.id !== action.payload.id), action.payload];
    default: {
      return state;
    }
  }
};
