import { Equipment } from '../types/equipment';
import { Actions } from '../actions/equipment';

interface EquipmentBySiteId {
  [key: number]: Equipment[];
}

const initialState: EquipmentBySiteId = {};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case Actions.EQUIPMENT_SUCCESS: {
      const { siteId, equipment } = action.payload;
      if (!siteId) return state;
      return {
        ...state,
        [siteId]: equipment,
      };
    }

    default:
      return state;
  }
};
