import { fetchApi } from '../middleware/apiMiddleware';
import { Service } from '../config';
import {
  CoolerRecommendationsResponse,
  EnergyRecommendationsResponse,
  HumidityRecommendationsResponse,
} from '../types/analytics';

export enum Actions {
  REFRIGATION_SUCCESS = '@@analytics/REFRIGATION_SUCCESS',
  REFRIGATION_LOADING = '@@analytics/REFRIGATION_LOADING',
  REFRIGATION_ERROR = '@@analytics/REFRIGATION_ERROR',

  ENERGY_SUCCESS = '@@analytics/ENERGY_SUCCESS',
  ENERGY_LOADING = '@@analytics/ENERGY_LOADING',
  ENERGY_ERROR = '@@analytics/ENERGY_ERROR',

  HUMIDITY_SUCCESS = '@@analytics/HUMIDITY_SUCCESS',
  HUMIDITY_LOADING = '@@analytics/HUMIDITY_LOADING',
  HUMIDITY_ERROR = '@@analytics/HUMIDITY_ERROR',
}

export const fetchCoolerRecommendations = (siteId: string, date?: string) => (
  dispatch: any
): Promise<CoolerRecommendationsResponse> => {
  dispatch({
    type: Actions.REFRIGATION_LOADING,
  });
  return fetchApi({
    endpoint: `/report/cooler_recommendation/${siteId}/${date || 'latest'}`,
    service: Service.analytics,
  }).then((response: CoolerRecommendationsResponse) => {
    dispatch({
      type: Actions.REFRIGATION_SUCCESS,
      payload: {
        siteId,
        data: response.data.filter(e => {
          return e.adjustmentType === 'decrease';
        }),
      },
    });
    return response;
  });
};

export const fetchEnergyRecommendations = (siteId: string, date?: string) => (
  dispatch: any
): Promise<EnergyRecommendationsResponse> => {
  dispatch({
    type: Actions.ENERGY_LOADING,
  });
  return fetchApi({
    endpoint: `/report/cooler_recommendation/${siteId}/${date || 'latest'}`,
    service: Service.analytics,
  }).then((response: EnergyRecommendationsResponse) => {
    dispatch({
      type: Actions.ENERGY_SUCCESS,
      payload: {
        siteId,
        data: response.data.filter(e => {
          return e.adjustmentType === 'increase';
        }),
      },
    });
    return response;
  });
};

export const fetchHumidityRecommendations = (siteId: string, date?: string) => (
  dispatch: any
): Promise<HumidityRecommendationsResponse> => {
  dispatch({
    type: Actions.HUMIDITY_LOADING,
  });
  return fetchApi({
    endpoint: `/report/humidity_recommendation/${siteId}/${date || 'latest'}`,
    service: Service.analytics,
  }).then((response: HumidityRecommendationsResponse) => {
    dispatch({
      type: Actions.HUMIDITY_SUCCESS,
      payload: {
        siteId,
        data: response.data,
      },
    });
    return response;
  });
};

/*
  // COOLER
  const foo = {
    _id: '5cb079b88d9d1a0001872643',
    key: 12837,
    report_date: '2019-04-11T00:00:00',
    data: [
      {
        equipmentId: '13022',
        equipmentTypeId: '5726',
        adjustmentType: 'increase',
        value: 7.945,
      },
    ],
  };
  */
