import { Actions as AppActions } from '../actions/app';
import { Actions as SiteActions } from '../actions/sites';
import { Actions as EquipmentActions } from '../actions/equipment';
import { Actions as NotificationActions } from '../actions/notifications';
import { Actions as GatewayActions } from '../actions/gateways';
import { Actions as SpaceActions } from '../actions/spaces';
import { Actions as SensorActions } from '../actions/sensors';

const initialState = {
  equipment: false,
  sites: false,
  app: false,
};

type Payload = {
  error: string;
  errors: any;
  exception: string;
  message: string;
  request: string;
  status: number;
  timestamp: string;
};

export default (state: any = initialState, action: any) => {
  switch (action.type) {
    case AppActions.LOGIN_LOADING:
      return {
        ...state,
        app: true,
      };

    case AppActions.LOGIN_SUCCESS:
    case AppActions.LOGIN_ERROR:
      return {
        ...state,
        app: false,
        failedLogin: action.payload ? action.payload.message : '',
      };

    case EquipmentActions.EQUIPMENT_LOADING: {
      return {
        ...state,
        equipment: true,
      };
    }
    case EquipmentActions.EQUIPMENT_SUCCESS:
    case EquipmentActions.EQUIPMENT_ERROR:
      return {
        ...state,
        equipment: false,
      };

    case SiteActions.SITES_LOADING: {
      return {
        ...state,
        sites: true,
      };
    }

    case SiteActions.SITES_SUCCESS:
    case SiteActions.SITES_ERROR:
      return {
        ...state,
        sites: false,
      };

    case NotificationActions.NOTIFICATIONS_LOADING: {
      return {
        ...state,
        notifications: true,
      };
    }

    case NotificationActions.NOTIFICATIONS_SUCCESS:
    case NotificationActions.NOTIFICATIONS_ERROR:
      return {
        ...state,
        notifications: false,
      };

    case GatewayActions.GATEWAYS_LOADING: {
      return {
        ...state,
        gateways: true,
      };
    }

    case GatewayActions.GATEWAYS_SUCCESS:
    case GatewayActions.GATEWAYS_ERROR:
      return {
        ...state,
        gateways: false,
      };

    case SpaceActions.SPACES_LOADING: {
      return {
        ...state,
        spaces: true,
      };
    }

    case SpaceActions.SPACES_SUCCESS:
    case SpaceActions.SPACES_ERROR:
      return {
        ...state,
        spaces: false,
      };

    case SensorActions.SENSORS_LOADING: {
      return {
        ...state,
        sensors: true,
      };
    }

    case SensorActions.SENSORS_SUCCESS:
    case SensorActions.SENSORS_ERROR:
      return {
        ...state,
        sensors: false,
      };

    default: {
      return state;
    }
  }
};
