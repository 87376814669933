import { equipmentStatus } from '../types/equipment';
import { Actions as EquipmentFilterActions } from '../actions/equipmentFilter';
import { Actions as EquipmentTagActions } from '../actions/equipmentTags';

import { EquipmentType } from '../types/tags';

type EquipmentFilter = { [key: string]: string[] };

export enum EquipmentFilters {
  STATUS = 'STATUS',
  TYPE = 'TYPE',
  SPACE = 'SPACE',
}

const initialState: EquipmentFilter = {
  [EquipmentFilters.STATUS]: [equipmentStatus.on],
  [EquipmentFilters.TYPE]: [],
  [EquipmentFilters.SPACE]: [],
};

function updateByValue(s: string[], needle: string): string[] {
  return s.includes(needle) ? s.filter(f => f !== needle) : [...s, needle];
}

export default (state: EquipmentFilter = initialState, action: any) => {
  switch (action.type) {
    case EquipmentFilterActions.STATUS: {
      return {
        ...state,
        [EquipmentFilters.STATUS]: updateByValue(
          state[EquipmentFilters.STATUS],
          action.payload
        ),
      };
    }

    case EquipmentFilterActions.TYPE: {
      return {
        ...state,
        [EquipmentFilters.TYPE]: action.payload,
      };
    }

    case EquipmentTagActions.EQUIPMENT_TYPES_SUCCESS: {
      return {
        ...state,
        [EquipmentFilters.TYPE]: action.payload.map((e: EquipmentType) => e.id),
      };
    }

    case EquipmentFilterActions.SPACE: {
      return {
        ...state,
        [EquipmentFilters.SPACE]: updateByValue(
          state[EquipmentFilters.SPACE],
          action.payload
        ),
      };
    }

    default: {
      return state;
    }
  }
};
