import { fetchApi } from '../middleware/apiMiddleware';
import { Service } from '../config';
import {
  ApiOperationalSamplesResponse,
  OperationalSample,
} from '../types/samples';

const apiBase = '/v3/operational_sensors';

export enum Actions {
  GET_OPERATIONAL_SAMPLES_BY_SENSOR_ID_SUCCESS = '@operational_samples/GET_OPERATIONAL_SAMPLES_BY_SENSOR_ID_SUCCESS',
  GET_OPERATIONAL_SAMPLES_BY_SENSOR_ID_ERROR = '@operational_samples/GET_OPERATIONAL_SAMPLES_BY_SENSOR_ID_ERROR',
  GET_OPERATIONAL_SAMPLES_BY_SENSOR_ID_LOADING = '@operational_samples/GET_OPERATIONAL_SAMPLES_BY_SENSOR_ID_LOADING',
}

export type GetOperationalSamplesParams = {
  'filter[sensor]': string;
  'filter[from]'?: number;
  'filter[to]'?: number;
};

const setNotificationFilter = (
  filter?: GetOperationalSamplesParams
): string => {
  const queryParams = new URLSearchParams();

  if (filter && filter['filter[sensor]']) {
    queryParams.set('filter[sensor]', filter['filter[sensor]'].toString());
  }

  if (filter && filter['filter[from]']) {
    queryParams.set('filter[from]', filter['filter[from]'].toString());
  }

  if (filter && filter['filter[to]']) {
    queryParams.set('filter[to]', filter['filter[to]'].toString());
  }

  return queryParams.toString();
};

function transformSamples(samples: ApiOperationalSamplesResponse): any {
  return samples.data.map(s => ({
    type: s.type.toUpperCase(),
    ...s.attributes,
  }));
}

export const fetchOperationalSamples = async (
  queryParams: string
): Promise<OperationalSample[]> =>
  fetchApi({
    endpoint: `${apiBase}?${queryParams}`,
    service: Service.tsd,
  }).then(transformSamples);

export const getOperationalSamplesBySensorId = (
  sensorId: string | number,
  params: GetOperationalSamplesParams,
  notificationId?: string | number
) => ({
  service: Service.tsd,
  type: 'API_GET',
  path: `${apiBase}?${setNotificationFilter(params)}`,
  success: {
    type: Actions.GET_OPERATIONAL_SAMPLES_BY_SENSOR_ID_SUCCESS,
    sensorId,
    notificationId,
  },
  error: {
    type: Actions.GET_OPERATIONAL_SAMPLES_BY_SENSOR_ID_ERROR,
    sensorId,
    notificationId,
  },
  loading: {
    type: Actions.GET_OPERATIONAL_SAMPLES_BY_SENSOR_ID_LOADING,
    sensorId,
    notificationId,
  },
});
