import { Actions } from '../actions/equipmentTags';
import { EquipmentTags } from '../types/tags';

export default (
  state: EquipmentTags = {
    equipmentGroups: [],
    equipmentTypes: [],
  },
  action: any
) => {
  switch (action.type) {
    case Actions.EQUIPMENT_GROUPS_SUCCESS:
      return {
        ...state,
        equipmentGroups: action.payload,
      };
    case Actions.EQUIPMENT_TYPES_SUCCESS: {
      return {
        ...state,
        equipmentTypes: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
