export const SET_SITE_FILTER = 'SET_SITE_FILTER';
export const SET_SITE_CONNECTIVITY_FILTER = 'SET_SITE_CONNECTIVITY_FILTER';

export const setSiteFilter = (filterString: string) => ({
  type: SET_SITE_FILTER,
  payload: filterString,
});

export const setConnectivitySiteFilter = (filterString: string) => ({
  type: SET_SITE_CONNECTIVITY_FILTER,
  payload: filterString,
});
