import { Actions } from '../actions/organizations';
import { Organizations } from '../types/organization';

const initialState: Organizations = {};

const normalizeCurrentOrganization = payload => ({
  _entity: payload._entity,
  country: payload.country,
  createdAt: payload.createdAt,
  description: payload.description,
  id: payload.id,
  legacyId: payload.legacyId,
  logo: payload.logo,
  title: payload.title,
  updatedAt: payload.updatedAt,
});

const organizations = (state: Organizations = initialState, action: any) => {
  switch (action.type) {
    case Actions.CURRENT_ORGANIZATION_SUCCESS: {
      return {
        ...state,
        currentOrganization: normalizeCurrentOrganization(action.payload),
      };
    }

    default:
      return state;
  }
};

export default organizations;
