// import * as R from 'ramda';

// Takes an array and converts it into object where keys are extracted from
// each item by given selector
export const mapArrayToObject = (data: any[], selector = 'id') =>
  data.reduce((result, record) => {
    const key = record[selector];
    return Object.assign(result, { [key]: record }, {});
  }, {});

// Map all values of the object with given function
export const mapValues = (data: Object, f: (value: any) => any) =>
  Object.keys(data).map(i => f(data[i]));

// Returns values of given object
export const values = (obj: Object) => Object.keys(obj).map(i => obj[i]);

export interface UserName {
  firstName: string;
  lastName: string;
}

export const fullName = ({ firstName, lastName }: UserName): string =>
  [firstName, lastName].filter(n => !!n).join(' ');

export const initials = ({ firstName, lastName }: UserName): string => {
  let result = '';
  result += firstName && firstName[0];
  result += lastName && lastName[0];

  return result.length > 0 ? result : '?';
};

export const hasSubstr = (s: string, q: string) =>
  (s || '').toLowerCase().indexOf((q || '').toLowerCase()) !== -1;

export const hasKeys = (o: { [k: string]: any }) =>
  Object.keys(o || {}).length > 0;

// Immutable version of "delete object.key"
export const dropKey = (o: { [_: string]: any }, k: string) =>
  Object.keys(o || {}).reduce((result, key) => {
    if (key !== k) result[key] = o[key];
    return result;
  }, {});

export enum SortDirection {
  ASC = 'asc',
  DESC = 'desc',
}

export const reverseSortDirection = (d: SortDirection) =>
  d === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC;

// FIXME: Super straight forward implementation of "pluralize" helper.
// Replace business logic if more sophisticated approach when required.
export const pluralize = (noun: string, count: number) =>
  count === 1 ? noun : `${noun}s`;

export const classNames = (...args: Array<any>): string => {
  const arr = Array.isArray(args[0]) ? [...args[0]] : args;
  return arr.filter(f => f).join(' ');
};

export interface GenericErrors {
  [k: string]: string[];
}

// Splits given list using given boolean predicate
// Returns array where first element contains all posivite outputs and seoncs element all negative
// export const split = (pred: (any) => boolean, data: any[]) => {
//   const { true: positive = [], false: negative = [] } = R.groupBy(
//     v => pred(v).toString(),
//     data
//   );

//   return [positive, negative];
// };

// Shallow replaces null values in any Object with undefined
export function replaceNullValues<T>(obj): T {
  return Object.entries(obj).reduce(
    (acc, [key, value]) => {
      acc[key] = value || undefined;
      return acc;
    },
    {} as T
  );
}

export function maskProperties<T>(obj: any, propertiesToReplace: String[]): T {
  return Object.entries(obj).reduce(
    (acc, [key, value]) => {
      if (propertiesToReplace.indexOf(key) !== -1) {
        acc[key] =
          '' +
          Math.random()
            .toString(36)
            .substr(2, 9);
      } else {
        acc[key] = value;
      }
      return acc;
    },
    {} as T
  );
}

export function removeWordsFromProperties<T>(
  obj: any,
  wordsToRemove: String[],
  propertiesToCheck: String[]
): T {
  return Object.entries(obj).reduce(
    (acc, [key, value]) => {
      if (propertiesToCheck.indexOf(key) !== -1 && typeof value === 'string') {
        acc[key] = (value as String).replace(
          new RegExp(`${wordsToRemove.join('|')}`, 'gi'),
          ''
        );
      } else {
        acc[key] = value;
      }
      return acc;
    },
    {} as T
  );
}

export function maskedResponse(apiResponse: any): any {
  const maskingFunctions = response => {
    return removeWordsFromProperties(
      maskProperties(response, [
        'address',
        'street',
        'city',
        'state',
        'country',
        'name',
        'email',
        'description',
        'firstName',
        'lastName',
      ]),
      ['holiday', 'AaRoN', 'platform', 'stationstores', 'inc'],
      ['title', 'name']
    );
  };

  if (Array.isArray(apiResponse)) {
    apiResponse = apiResponse.map(objMaybeString =>
      typeof objMaybeString === 'object'
        ? maskingFunctions(objMaybeString)
        : objMaybeString
    );
  } else {
    apiResponse = maskingFunctions(apiResponse);
  }

  Object.keys(apiResponse).forEach(key => {
    if (
      typeof apiResponse[key] === 'object' &&
      apiResponse[key] !== null &&
      apiResponse[key] !== undefined
    ) {
      apiResponse[key] = maskedResponse(apiResponse[key]);
    }
  });

  return apiResponse;
}
