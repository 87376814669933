import * as R from 'ramda';
import { Actions } from '../actions/samples';
import { OperationalSample, ApiOperationalSample } from '../types/samples';
import { mapValues } from '../util';

export interface OperationalSamples {
  operationalSamplesBySensorId: OperationalSamplesById;
  operationalSamplesByFeatureNotificationId: OperationalSamplesById;
}

export type OperationalSamplesById = {
  [id: string]: OperationalSampleById;
};

export type OperationalSampleById = {
  isLoading: boolean;
  operationalSamples: OperationalSample[];
};

type operationalSampleAttributeApiResponse = {
  sensorId: string;
  timestamp: number;
  temperature?: number;
  humidity?: number;
  state?: [boolean];
};

export const initialState = {
  operationalSamplesBySensorId: {},
  operationalSamplesByFeatureNotificationId: {},
};

const storeSampleApiResponsiveValue = (
  responseAttributes: operationalSampleAttributeApiResponse
) => {
  if (typeof responseAttributes.temperature === 'number') {
    return responseAttributes.temperature;
  }

  if (typeof responseAttributes.humidity === 'number') {
    return responseAttributes.humidity;
  }

  if (responseAttributes.state) {
    return (responseAttributes.state || [])[0];
  }
};

const ApiOperationalSampleResponse = (data: ApiOperationalSample) => ({
  id: data.id,
  type: data.type.toUpperCase(),
  sensorId: data.attributes.sensorId,
  timestamp: data.attributes.timestamp,
  value: storeSampleApiResponsiveValue(data.attributes),
});

// Storing samples most recent first -> least reacent last.
const operationalSamples = (
  state: OperationalSamples = initialState,
  action: any
) => {
  switch (action.type) {
    case Actions.GET_OPERATIONAL_SAMPLES_BY_SENSOR_ID_LOADING:
      return R.assocPath(
        action.notificationId
          ? [
              'operationalSamplesByFeatureNotificationId',
              action.notificationId,
              'isLoading',
            ]
          : ['operationalSamplesBySensorId', action.sensorId, 'isLoading'],
        true,
        state
      );

    case Actions.GET_OPERATIONAL_SAMPLES_BY_SENSOR_ID_SUCCESS:
      return R.pipe(
        R.assocPath(
          action.notificationId
            ? [
                'operationalSamplesByFeatureNotificationId',
                action.notificationId,
                'operationalSamples',
              ]
            : [
                'operationalSamplesBySensorId',
                action.sensorId,
                'operationalSamples',
              ],
          mapValues(
            [...action.payload.data].reverse(),
            ApiOperationalSampleResponse
          )
        ),
        R.assocPath(
          action.notificationId
            ? [
                'operationalSamplesByFeatureNotificationId',
                action.notificationId,
                'isLoading',
              ]
            : ['operationalSamplesBySensorId', action.sensorId, 'isLoading'],
          false
        )
      )(state);

    default:
      return state;
  }
};

export default operationalSamples;
