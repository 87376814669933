import * as R from 'ramda';
import { Sensor } from '../types/sensors';
import { Actions } from '../actions/sensors';

export interface Sensors {
  sensorsById: SensorsById;
  sensorsByEquipmentId: SensorsByEquipmentId;
  isLoading: boolean;
}

export type SensorsById = {
  [id: string]: Sensor;
};

export type SensorsByEquipmentId = {
  [equipmentId: string]: Sensor;
};

const initialState = {
  isLoading: false,
  sensorsById: {},
  sensorsByEquipmentId: {},
};

export const sortSensorsByRecentDate = (sensorList: Sensor[]) => {
  return sensorList.sort((a: Sensor, b: Sensor) => {
    let sensorA: number, sensorB: number;

    if (!a.sensorStatus || !(a.sensorStatus && a.sensorStatus.receivedAt)) {
      sensorA = 0;
    } else sensorA = +new Date(a.sensorStatus.receivedAt);

    if (!b.sensorStatus || !(b.sensorStatus && b.sensorStatus.receivedAt)) {
      sensorB = 0;
    } else sensorB = +new Date(b.sensorStatus.receivedAt);

    return sensorB - sensorA;
  });
};

export default (state: Sensors = initialState, action: any) => {
  switch (action.type) {
    case Actions.BY_ID_SUCCESS:
      return R.assocPath(
        ['sensorsById', action.payload.id],
        action.payload,
        state
      );

    case Actions.GET_SENSORS_BY_EQUIPMENT_ID_SUCCESS:
      return R.pipe(
        R.assoc('isLoading', false),
        R.assocPath(
          ['sensorsByEquipmentId', action.equipmentId],
          sortSensorsByRecentDate(action.payload)
        )
      )(state);

    case Actions.GET_SENSORS_BY_EQUIPMENT_ID_LOADING:
      return R.assoc('isLoading', true, state);

    case Actions.GET_SENSORS_BY_EQUIPMENT_ID_ERROR:
      return R.assoc('isLoading', false, state);

    default: {
      return state;
    }
  }
};
