import { Actions as AppActions } from '../actions/app';
import { Actions as UserActions } from '../actions/user';
import { navigate } from '@reach/router';
import { getUserInfo, getUserType } from '../actions/user';
import {
  getEquipmentGroups,
  getEquipmentTypes,
} from '../actions/equipmentTags';

const STORE_KEY = 'authToken';

const getToken = (): string | null => {
  return window.localStorage.getItem(STORE_KEY);
};

const setToken = (authToken: string) => {
  window.localStorage.setItem(STORE_KEY, authToken);
};

const removeToken = () => {
  window.localStorage.removeItem(STORE_KEY);
};

const authMiddleware = store => next => action => {
  switch (action.type) {
    case AppActions.INIT:
      const accessToken = getToken();

      if (accessToken) {
        store.dispatch({
          type: AppActions.LOGIN_SUCCESS,
          payload: { accessToken },
        });
      }
      break;

    case AppActions.INIT_WITH_TOKEN:
      if (action.payload.accessToken) {
        store.dispatch({
          type: AppActions.LOGIN_SUCCESS,
          payload: { accessToken: action.payload.accessToken },
        });
      }
      break;

    case AppActions.LOGIN_SUCCESS:
    case AppActions.CHANGE_ORGANIZATION_SUCCESS:
      setToken(action.payload.accessToken);

      store.dispatch({
        type: AppActions.ACCESS_TOKEN_UPDATED,
        payload: action.payload,
      });

      store.dispatch(getUserInfo());
      store.dispatch(getEquipmentGroups());
      store.dispatch(getEquipmentTypes());
      store.dispatch(getUserType());

      const searchParams = new URLSearchParams(window.location.search);
      const redirect = searchParams.get('next');
      if (redirect !== null) {
        navigate(redirect);
        break;
      }

      // if user logs in and token is valid
      if (['/', '/login'].includes(window.location.pathname) === false) {
        navigate(window.location.pathname);
        break;
      }

      navigate('/');
      break;

    case AppActions.LOGOUT:
      removeToken();

      store.dispatch({
        type: AppActions.ACCESS_TOKEN_CLEARED,
      });

      navigate('/');
      window.location.reload();
      break;

    case UserActions.USER_CHANGE_PASSWORD_SUCCESS:
      navigate('/');
      break;
  }

  next(action);
};

export default authMiddleware;
