import { Actions } from '../actions/sensors';
import { Sensor } from '../types/sensors';

interface SensorsBySiteId {
  [key: number]: Sensor[];
}

const initialState: SensorsBySiteId = {};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case Actions.SENSORS_SUCCESS: {
      const { siteId, sensors } = action.payload;
      if (!siteId) return state;
      return {
        ...state,
        [siteId]: sensors,
      };
    }

    default:
      return state;
  }
};
