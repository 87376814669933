import { Notification } from '../types/notification';
import { Actions } from '../actions/notifications';
import { SentinelPriorityLevel } from '../types/sentinel';
// import { FilterActions } from '../actions/notificationFilter';

const initialState = {
  filteredNotifications: [],
  isLoading: false,
};

interface filteredNotifications {
  isLoading: boolean;
  filteredNotifications: Notification[];
}

function reverseHandlingLogs(notification: Notification): Notification {
  const { handlingLogs, ...rest } = notification;
  return {
    ...rest,
    handlingLogs: [...handlingLogs].reverse(),
  };
}

function sortByDateDescending(a: Notification, b: Notification): number {
  return (
    new Date(b.handlingLogs[0].at).valueOf() -
    new Date(a.handlingLogs[0].at).valueOf()
  );
}

const priorityMap = {
  [SentinelPriorityLevel.NORMAL]: 1,
  [SentinelPriorityLevel.MEDIUM]: 2,
  [SentinelPriorityLevel.HIGH]: 3,
  [SentinelPriorityLevel.HIGHEST]: 4,
};

function sortByPriority(a: Notification, b: Notification): number {
  const aPrio =
    priorityMap[a.handlingLogs[0] && a.handlingLogs[0].priorityLevel] || 0;
  const bPrio =
    priorityMap[b.handlingLogs[0] && b.handlingLogs[0].priorityLevel] || 0;

  return bPrio - aPrio;
}

function sortByPriorityDateDescending(
  a: Notification,
  b: Notification
): number {
  return sortByPriority(a, b) === 0
    ? sortByDateDescending(a, b)
    : sortByPriority(a, b);
}

function sortByPriorityDateUnseen(a: Notification, b: Notification): number {
  if (a.dismissedBy) {
    if (b.dismissedBy) {
      return sortByPriorityDateDescending(a, b);
    }
    return 1;
  } else if (b.dismissedBy) {
    return -1;
  }
  return sortByPriorityDateDescending(a, b);
}

export default (state: filteredNotifications = initialState, action: any) => {
  switch (action.type) {
    case Actions.FILTERED_NOTIFICATIONS_LOADING: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case Actions.FILTERED_NOTIFICATIONS_SUCCESS: {
      return {
        isLoading: false,
        filteredNotifications: action.payload
          .map(n => reverseHandlingLogs(n))
          .sort(sortByPriorityDateUnseen),
      };
    }

    case Actions.FILTERED_NOTIFICATIONS_CLEAR: {
      return initialState;
    }

    case Actions.FILTERED_DISMISS_SUCCESS: {
      const notificationIndex = state.filteredNotifications.findIndex(
        e => e.id === action.payload.id
      );

      return {
        ...state,
        isLoading: false,
        filteredNotifications: [
          ...state.filteredNotifications.slice(0, notificationIndex),
          reverseHandlingLogs(action.payload),
          ...state.filteredNotifications.slice(notificationIndex + 1),
        ].sort(sortByPriorityDateUnseen),
      };
    }

    case Actions.FILTERED_DISMISS_LOADING: {
      return {
        ...state,
        isLoading: true,
      };
    }

    // TODO: error handling across Pulse app
    case Actions.FILTERED_DISMISS_ERROR: {
      return {
        ...state,
        isLoading: false,
      };
    }

    default: {
      return state;
    }
  }
};
