import { Actions } from '../actions/analytics';
import { HumidityRecommendation } from '../types/analytics';

const initialState = {};
interface State {
  [key: string]: HumidityRecommendation[];
}

export default (state: State = initialState, action: any) => {
  switch (action.type) {
    case Actions.HUMIDITY_SUCCESS: {
      const { siteId, data } = action.payload;
      if (!siteId) return state;
      return {
        ...state,
        [siteId]: data,
      };
    }

    default:
      return state;
  }
};
