import notificationDateFilter from '../utils/notificationDateFilter';
import {
  NotificationFilter,
  NotificationStatus,
  NotificationPriority,
} from '../types/notification';
import { Actions } from '../actions/notificationFilter';

const initialState: NotificationFilter = {
  notificationTypes: [],
  equipment: [],
  timePeriod: {
    title: notificationDateFilter[2].title,
    fromDate: notificationDateFilter[2].from,
    toDate: notificationDateFilter[2].to,
  },
  equipmentIds: [],
  siteIds: [],
  status: Object.keys(NotificationStatus),
  priority: Object.keys(NotificationPriority),
};

export default (state: NotificationFilter = initialState, action: any) => {
  switch (action.type) {
    case Actions.PRIORITY: {
      return {
        ...state,
        priority: action.payload,
      };
    }
    case Actions.SITES: {
      return {
        ...state,
        siteIds: action.payload,
      };
    }

    case Actions.STATUS: {
      return {
        ...state,
        status: action.payload,
      };
    }

    case Actions.DATE: {
      return {
        ...state,
        timePeriod: action.payload,
      };
    }

    case Actions.EQUIPMENT: {
      return {
        ...state,
        equipmentIds: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};
