import {
  SET_SITE_FILTER,
  SET_SITE_CONNECTIVITY_FILTER,
} from '../actions/sitesFilter';

const initialState = '';
type State = string;

export default (state: State = initialState, action: any) => {
  switch (action.type) {
    case SET_SITE_CONNECTIVITY_FILTER: {
      return action.payload;
    }
    case SET_SITE_FILTER: {
      return action.payload;
    }
    default:
      return state;
  }
};
