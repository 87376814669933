import * as R from 'ramda';
import { Actions } from '../actions/sentinels';
import { Sentinel } from '../types/sentinel';
import { replaceNullValues } from '../util';

export type SentinelState = {
  isLoading: boolean;
  byId: SentinelsById;
  sentinelsBySensorId: SentinelsBySensorId;
};

export type SentinelsById = {
  [id: string]: Sentinel[];
};

export type SentinelsBySensorId = {
  [id: string]: SentinelBySensorId;
};

export type SentinelBySensorId = {
  isLoading: boolean;
  sentinels: Sentinel[];
};

const initialState = {
  isLoading: false,
  byId: {},
  sentinelsBySensorId: {},
};

const sanitizeSentinelsApiReturn = (payload: Sentinel[]) => {
  return {
    ...payload.reduce(
      (obj, s: Sentinel) => ({ ...obj, [s.id]: replaceNullValues(s) }),
      {}
    ),
  };
};

export default (state: SentinelState = initialState, action: any) => {
  switch (action.type) {
    case Actions.GET_SENTINELS_LOADING:
      return R.assoc('isLoading', true, state);

    case Actions.SENTINELS_SUCCESS:
    case Actions.GET_SENTINELS_SUCCESS:
      return R.pipe(
        R.assoc('isLoading', false),
        R.assoc('byId', sanitizeSentinelsApiReturn(action.payload))
      )(state);

    case Actions.GET_SENTINELS_BY_SENSOR_ID_LOADING:
      return R.assocPath(
        ['sentinelsBySensorId', action.sensorId, 'isLoading'],
        true,
        state
      );

    case Actions.GET_SENTINELS_BY_SENSOR_ID_SUCCESS:
      return R.pipe(
        R.assocPath(
          ['sentinelsBySensorId', action.sensorId, 'isLoading'],
          false
        ),
        R.assocPath(
          ['sentinelsBySensorId', action.sensorId, 'sentinels'],
          action.payload.map(s => replaceNullValues(s))
        )
      )(state);

    case Actions.DELETE_SUCCESS:
      return R.dissocPath(['byId', action.id], state);

    case Actions.MUTE_SUCCESS:
      return R.pipe(
        R.assoc('isLoading', false),
        R.assocPath(['byId', action.id], {
          ...state.byId[action.id],
          muted: true,
        })
      )(state);

    case Actions.RESUME_SUCCESS:
      return R.pipe(
        R.assoc('isLoading', false),
        R.assocPath(['byId', action.id], {
          ...state.byId[action.id],
          muted: false,
        })
      )(state);

    default: {
      return state;
    }
  }
};
