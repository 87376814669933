import { SentinelPriorityLevel, SentinelType } from './sentinel';
import { SensorType } from './sensors';

export enum NotificationStatus {
  unseen = 'unseen',
  dismissed = 'dismissed',
}

export enum NotificationPriority {
  normal = 'normal',
  medium = 'medium',
  high = 'high',
  highest = 'highest',
}

export type NotificationsParams = {
  // todo: check with sentinel API
  sensorId: string;
  summary: string;
  type: SensorType;
  value: number | boolean | string;
};

export type HandlingLog = {
  at: string;
  ix: number;
  priorityLevel: SentinelPriorityLevel;
  name: string;
  message?: string;
  sensorParams: NotificationsParams[];
};

export type Notification = {
  id: string;
  createdAt: string;
  updatedAt: string;
  dismissedAt: string;
  dismissedBy: string;
  dismissedComment: string;
  sensorParams: NotificationsParams[];
  sentinelId: string;
  sentinelTitle: string;
  sentinelType: SentinelType;
  handlingLogs: HandlingLog[];
};

export type FilterTimePeriod = {
  title?: string;
  fromDate: string;
  toDate: string;
  isCustomRange?: boolean;
  minuteOffset?: number;
};

export type FnSetFilterTimePeriod = (
  filterTimePeriod: FilterTimePeriod
) => void;

export type NotificationFilter = {
  notificationTypes: any[];
  equipment: any[];
  timePeriod: FilterTimePeriod;
  siteIds: number[];
  status: any[];
  equipmentIds: number[];
  priority: string[];
};

export type DismissSubmitObject = {
  comment: string;
  notificationId: string;
};

export type SortOptionValues = 'date' | 'unseen';

export enum NotificationCountType {
  site = 'site',
  sensor = 'sensor',
  equipment = 'equipment',
  space = 'space',
  gateway = 'gateway',
  all = 'all',
}

export interface NotificationCountsBySiteId {
  [key: string]: any;
}

export interface NotificationsByEquipmentId {
  [key: number]: Notification[];
}

export type NotificationApiFilter = {
  equipmentIds?: number[];
  sensorIds?: number[];
  siteIds?: number[] | string;
  spaceIds?: number[];
  state?: string;
  limit?: number;
  fromDate?: string;
  toDate?: string;
};
