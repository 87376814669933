import { fetchApi } from '../middleware/apiMiddleware';
import { Service } from '../config';
import {
  Notification,
  NotificationApiFilter,
  NotificationCountType,
} from '../types/notification';
import addMinutes from 'date-fns/addMinutes';
import { sortByPriorityDateUnseen } from '../reducers/notifications';
import { SentinelType } from '../types/sentinel';

export enum Actions {
  NOTIFICATIONS_SUCCESS = '@@notifications/NOTIFICATIONS_SUCCESS',
  NOTIFICATIONS_LOADING = '@@notifications/NOTIFICATIONS_LOADING',
  NOTIFICATIONS_ERROR = '@@notifications/NOTIFICATIONS_ERROR',
  NOTIFICATIONS_CLEAR = '@@notifications/NOTIFICATIONS_CLEAR',
  DISMISS_SUCCESS = '@@notifications/DISMISS_SUCCESS',
  DISMISS_LOADING = '@@notifications/DISMISS_LOADING',
  DISMISS_ERROR = '@@notifications/DISMISS_ERROR',
  FILTERED_NOTIFICATIONS_SUCCESS = '@@notifications/FILTERED_NOTIFICATIONS_SUCCESS',
  FILTERED_NOTIFICATIONS_LOADING = '@@notifications/FILTERED_NOTIFICATIONS_LOADING',
  FILTERED_NOTIFICATIONS_ERROR = '@@notifications/FILTERED_NOTIFICATIONS_ERROR',
  FILTERED_NOTIFICATIONS_CLEAR = '@@notifications/FILTERED_NOTIFICATIONS_CLEAR',
  FILTERED_DISMISS_SUCCESS = '@@notifications/FILTERED_DISMISS_SUCCESS',
  FILTERED_DISMISS_LOADING = '@@notifications/FILTERED_DISMISS_LOADING',
  FILTERED_DISMISS_ERROR = '@@notifications/FILTERED_DISMISS_ERROR',
  COUNT_SUCCESS = '@@notifications/COUNT_SUCCESS',
  COUNT_LOADING = '@@notifications/COUNT_LOADING',
  COUNT_ERROR = '@@notifications/COUNT_ERROR',
  ORDER_UNSEEN = '@@notifications/ORDER_UNSEEN',
  ORDER_DATE = '@@notifications/ORDER_DATE',

  GET_NOTIFICATIONS_BY_SENSOR_ID_ERROR = '@@notifications/GET_NOTIFICATIONS_BY_SENSOR_ID_ERROR',
  GET_NOTIFICATIONS_BY_SENSOR_ID_LOADING = '@@notifications/GET_NOTIFICATIONS_BY_SENSOR_ID_LOADING',
  GET_NOTIFICATIONS_BY_SENSOR_ID_SUCCESS = '@@notifications/GET_NOTIFICATIONS_BY_SENSOR_ID_SUCCESS',
}

// Regional manager is only interested in operations related
// notifications, not gateway online status or sensor status etc.
export const REGIONAL_MANAGER_NOTIFICATIONS = [
  SentinelType.TEMPERATURE,
  SentinelType.HUMIDITY,
  SentinelType.DOOR,
  SentinelType.OPEN_COUNTER,
  SentinelType.ACTIVE_POWER_THRESHOLD,
];

const setNotificationFilter = (filter?: NotificationApiFilter): string => {
  const queryParams = new URLSearchParams();

  if (filter && filter.equipmentIds) {
    queryParams.set('equipmentIds', filter.equipmentIds.join(','));
  }

  if (filter && filter.sensorIds) {
    queryParams.set('sensorIds', filter.sensorIds.join(','));
  }

  if (filter && filter.siteIds) {
    if (typeof filter.siteIds === 'string')
      queryParams.set('siteIds', filter.siteIds);
    else {
      queryParams.set('siteIds', filter.siteIds.join(','));
    }
  }

  if (filter && filter.spaceIds) {
    queryParams.set('spaceIds', filter.spaceIds.join(','));
  }

  if (filter && filter.state) {
    queryParams.set('state', filter.state.toString());
  }

  if (filter && filter.limit) {
    queryParams.set('limit', filter.limit.toString());
  }

  if (filter && filter.fromDate) {
    queryParams.set('fromDate', filter.fromDate);
  }

  if (filter && filter.toDate) {
    queryParams.set('toDate', filter.toDate);
  }

  if (filter && filter.limit) {
    queryParams.set('limit', filter.limit.toString());
  } else {
    queryParams.set('limit', '1000');
  }

  return queryParams.toString();
};

export const clearFilteredNotifications = () => ({
  type: Actions.FILTERED_NOTIFICATIONS_CLEAR,
});

export const setReducerNotifications = notifications => ({
  type: Actions.NOTIFICATIONS_SUCCESS,
  payload: notifications,
});

export const fetchNotificationCount = (
  filter?: NotificationApiFilter
): Promise<{ count: number }> =>
  fetchApi({
    endpoint: `/api/v1/count/notifications?${setNotificationFilter(filter)}`,
    service: Service.sentinel,
  });

export const fetchNotificationCountsBySite = async (
  queryString,
  keys = [
    ...REGIONAL_MANAGER_NOTIFICATIONS,
    SentinelType.BATTERY_LEVEL_CHECK,
    SentinelType.CONNECTIVITY,
  ]
) => {
  try {
    const response = await fetchApi({
      endpoint: `/api/v1/count/notifications/by-site-and-sentineltype?${queryString}`,
      service: Service.sentinel,
    });

    return Object.keys(response).reduce((acc, siteKey) => {
      const siteNotifications: any = {};
      keys.forEach(key => {
        const keyNotifications = response[siteKey][key];
        siteNotifications[key] = keyNotifications || 0;
      });

      if (Object.keys(siteNotifications).length === 0) {
        return acc;
      } else
        return {
          ...acc,
          [siteKey]: siteNotifications,
        };
    }, {});
  } catch (e) {
    return Promise.reject(e);
  }
};

export const fetchNotificationsList = (
  queryParams?: NotificationApiFilter
): Promise<Notification[]> =>
  fetchApi({
    endpoint: `/api/v1/notifications?${setNotificationFilter(queryParams)}`,
    service: Service.sentinel,
  });

export const setFilteredNotifications = (notifications?: any) => ({
  type: Actions.FILTERED_NOTIFICATIONS_SUCCESS,
  payload: notifications,
});

export const getFilteredNotifications = (
  queryParams?: NotificationApiFilter
) => ({
  type: 'API_GET',
  path: `/api/v1/notifications?${setNotificationFilter(queryParams)}`,
  service: Service.sentinel,
  loading: Actions.FILTERED_NOTIFICATIONS_LOADING,
  success: Actions.FILTERED_NOTIFICATIONS_SUCCESS,
  error: Actions.FILTERED_NOTIFICATIONS_ERROR,
});

// XXX: API does not support timestamps, do e.g within 24 hours by fetching
// today and yesterday and filtering too old ones out
export const fetchNotificationsAfter = (
  queryParams?: NotificationApiFilter,
  minuteOffset?: number
) => {
  return fetchNotificationsList(queryParams).then(result => {
    if (!minuteOffset) return result.sort(sortByPriorityDateUnseen);
    const after = addMinutes(new Date(), minuteOffset);
    return result
      .filter(
        (notification: Notification) =>
          notification.handlingLogs[0] &&
          new Date(notification.handlingLogs[0].at).getTime() > after.getTime()
      )
      .sort(sortByPriorityDateUnseen);
  });
};

export const getNotifications = (queryParams?: NotificationApiFilter) => ({
  type: 'API_GET',
  path: `/api/v1/notifications?${setNotificationFilter(queryParams)}`,
  service: Service.sentinel,
  loading: Actions.NOTIFICATIONS_LOADING,
  success: Actions.NOTIFICATIONS_SUCCESS,
  error: Actions.NOTIFICATIONS_ERROR,
});

export const getNotificationsBySensorId = (
  sensorId: string | number,
  queryParams: NotificationApiFilter,
  notificationId?: string | number
) => ({
  type: 'API_GET',
  path: `/api/v1/notifications?${setNotificationFilter(queryParams)}`,
  service: Service.sentinel,
  loading: {
    type: Actions.GET_NOTIFICATIONS_BY_SENSOR_ID_LOADING,
    sensorId,
    notificationId,
  },
  success: {
    type: Actions.GET_NOTIFICATIONS_BY_SENSOR_ID_SUCCESS,
    sensorId,
    notificationId,
  },
  error: {
    type: Actions.GET_NOTIFICATIONS_BY_SENSOR_ID_ERROR,
    sensorId,
    notificationId,
  },
});

export interface ClearNotifications {
  (): void;
}
export const clearNotifications = () => ({
  type: Actions.NOTIFICATIONS_CLEAR,
});

export const dismissNotificationById = (id: string, comment: string) => ({
  type: 'API_PUT',
  path: `/api/v1/notifications/${id}/dismiss`,
  payload: {
    dismissedComment: comment,
  },
  service: Service.sentinel,
  loading: Actions.DISMISS_LOADING,
  success: Actions.DISMISS_SUCCESS,
  error: Actions.DISMISS_ERROR,
});

export const dismissFilteredNotificationById = (
  id: string,
  comment: string
) => ({
  type: 'API_PUT',
  path: `/api/v1/notifications/${id}/dismiss`,
  payload: {
    dismissedComment: comment,
  },
  service: Service.sentinel,
  loading: Actions.FILTERED_DISMISS_LOADING,
  success: Actions.FILTERED_DISMISS_SUCCESS,
  error: Actions.FILTERED_DISMISS_ERROR,
});

export interface OrderNotificationsByUnseen {
  (): void;
}

export const orderNotificationsByUnseen: OrderNotificationsByUnseen = () => ({
  type: Actions.ORDER_UNSEEN,
});

export interface OrderNotificationsByDate {
  (): void;
}

export const orderNotificationsByDate = () => ({
  type: Actions.ORDER_DATE,
});

export const fetchUnseenNotificationCountByResourceId = (
  resourceId: number,
  type: NotificationCountType
) =>
  fetchApi({
    endpoint: `/api/v1/count/notifications?state=unseen&${type}Ids=${resourceId}`,
    service: Service.sentinel,
    method: 'GET',
  });

export const fetchAllUnseenNotificationCount = () =>
  fetchApi({
    endpoint: `/api/v1/count/notifications?state=unseen`,
    service: Service.sentinel,
    method: 'GET',
  });

export type GetAllUnseenNotificationCount = (
  resourceId?: number,
  type?: NotificationCountType
) => Promise<void>;

export const getAllUnseenNotificationCount = (
  resourceId?: number,
  type?: NotificationCountType
) => async (dispatch: any): Promise<any> => {
  dispatch({ type: Actions.COUNT_LOADING });

  try {
    const { count } =
      resourceId && type
        ? await fetchUnseenNotificationCountByResourceId(resourceId, type)
        : await fetchAllUnseenNotificationCount();
    dispatch({ type: Actions.COUNT_SUCCESS, payload: { count } });
  } catch (e) {
    dispatch({ type: Actions.COUNT_ERROR });
  }
};
