import { fetchApi } from '../middleware/apiMiddleware';
import {
  EnergyApiFilter,
  EnergyBySiteEquipmentGroupResponse,
  RecentPowerFromEquipmentApiResponse,
  EnergyBySiteEquipmentTypeResponse,
} from '../types/energy';

export enum Actions {
  ENERGY_BY_SITE_EQUIPMENT_GROUP_SUCCESS = '@@energy/ENERGY_BY_SITE_EQUIPMENT_GROUP_SUCCESS',
  ENERGY_BY_SITE_EQUIPMENT_GROUP_LOADING = '@@energy/ENERGY_BY_SITE_EQUIPMENT_GROUP_LOADING',
  ENERGY_BY_SITE_EQUIPMENT_GROUP_ERROR = '@@energy/ENERGY_BY_SITE_EQUIPMENT_GROUP_ERROR',

  ENERGY_BY_SITE_EQUIPMENT_TYPE_SUCCESS = '@@energy/ENERGY_BY_SITE_EQUIPMENT_TYPE_SUCCESS',
  ENERGY_BY_SITE_EQUIPMENT_TYPE_LOADING = '@@energy/ENERGY_BY_SITE_EQUIPMENT_TYPE_LOADING',
  ENERGY_BY_SITE_EQUIPMENT_TYPE_ERROR = '@@energy/ENERGY_BY_SITE_EQUIPMENT_TYPE_ERROR',

  ENERGY_BY_EQUIPMENT_ID_SUCCESS = '@@energy/ENERGY_BY_EQUIPMENT_ID_SUCCESS',
  ENERGY_BY_EQUIPMENT_ID_LOADING = '@@energy/ENERGY_BY_EQUIPMENT_ID_LOADING',
  ENERGY_BY_EQUIPMENT_ID_ERROR = '@@energy/ENERGY_BY_EQUIPMENT_ID_ERROR',
}

export const setEnergyFilter = (filter?: EnergyApiFilter): string => {
  const queryParams = new URLSearchParams();

  //filter by equipmentId
  if (filter && filter.id) {
    queryParams.set('id', filter.id.toString());
  }

  if (filter && filter.from) {
    queryParams.set('from', filter.from.toString());
  }

  if (filter && filter.to) {
    queryParams.set('to', filter.to.toString());
  }

  return queryParams.toString();
};

export const getEnergyByEquipmentId = (
  queryParams: EnergyApiFilter,
  equipmentId: number
) => ({
  type: 'API_GET',
  path: `/api/v1/ioe-energy/consumption/equipment/${equipmentId}?${setEnergyFilter(
    queryParams
  )}`,
  loading: Actions.ENERGY_BY_EQUIPMENT_ID_LOADING,
  success: Actions.ENERGY_BY_EQUIPMENT_ID_SUCCESS,
  error: Actions.ENERGY_BY_EQUIPMENT_ID_ERROR,
});

export const fetchRecentPowerByEquipment = (
  equipmentId: number
): Promise<RecentPowerFromEquipmentApiResponse> =>
  fetchApi({
    endpoint: `/api/v1/ioe-energy/recent-power/equipment/${equipmentId}`,
  });

export const getEnergyBySiteEquipmentGroup = (
  id: number,
  from: number,
  to: number
) => (dispatch: any): Promise<EnergyBySiteEquipmentGroupResponse> => {
  dispatch({
    type: Actions.ENERGY_BY_SITE_EQUIPMENT_GROUP_LOADING,
  });
  const queryParams = new URLSearchParams();

  queryParams.set('from', from.toString());
  queryParams.set('to', to.toString());

  return fetchApi({
    endpoint: `/api/v1/ioe-energy/total/by-equipment-groups/site/${id}?${queryParams.toString()}`,
  }).then((response: EnergyBySiteEquipmentGroupResponse) => {
    dispatch({
      type: Actions.ENERGY_BY_SITE_EQUIPMENT_GROUP_SUCCESS,
      payload: response,
    });
    return response;
  });
};

export const getEnergyBySiteEquipmentTypes = (
  id: number,
  to: number,
  from: number
) => (dispatch: any): Promise<void | EnergyBySiteEquipmentTypeResponse> => {
  dispatch({
    type: Actions.ENERGY_BY_SITE_EQUIPMENT_TYPE_LOADING,
  });
  const queryParams = new URLSearchParams();

  queryParams.set('to', to.toString());
  queryParams.set('from', from.toString());

  return fetchApi({
    endpoint: `/api/v1/ioe-energy/total/by-equipment-types/site/${id}?${queryParams.toString()}`,
  })
    .then((response: EnergyBySiteEquipmentTypeResponse) => {
      dispatch({
        type: Actions.ENERGY_BY_SITE_EQUIPMENT_TYPE_SUCCESS,
        payload: response,
      });
      return response;
    })
    .catch(err => {
      console.log(err);
    });
};
