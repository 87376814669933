import { MeasurementSystem } from './shared';

export enum UserRole {
  'VIEWER' = 'VIEWER',
  'MANAGER' = 'MANAGER',
  'ADMIN' = 'ADMIN',
}

export enum OrganizationRole {
  SITE_MANAGER = 'SITE_MANAGER',
  REGIONAL_MANAGER = 'REGIONAL_MANAGER',
}

export function getRoleTitle(role: UserRole | string): string {
  return role.charAt(0) + role.slice(1).toLowerCase();
}

export type User = {
  id: number;
  createdAt: string;
  updatedAt?: string;
  lastLoginAt?: string;
  organizationId: number;
  title: string;
  role?: UserRole;
  firstName?: string;
  lastName?: string;
  email: string;
  measurementSystem?: MeasurementSystem;
};
