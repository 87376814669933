import { Actions } from '../actions/equipment';

const initialState = { equipment: [], loading: false };

export default (state = initialState, action: any) => {
  switch (action.type) {
    case Actions.EQUIPMENT_BY_SITES: {
      return { ...state, equipment: action.payload };
    }
    case Actions.EQUIPMENT_BY_SITES_LOADING: {
      return { ...state, loading: true };
    }
    case Actions.EQUIPMENT_BY_SITES_SUCCESS: {
      return { ...state, loading: false };
    }
    default:
      return state;
  }
};
