export enum SentinelType {
  NONE = '',
  TEMPERATURE = 'BasicTemperatureThreshold',
  HUMIDITY = 'BasicHumidityThreshold',
  DOOR = 'BasicDoorSwitch',
  BATTERY_LEVEL_CHECK = 'BatteryLevelCheck',
  CONNECTIVITY = 'OnlineStatusCheck',
  OPEN_COUNTER = 'DoorOpenCounter',
  CAR_WASH = 'TemperatureAndDoorCheck',
  BINARY_COUNTER = 'DoorOpenCounter',
  CUSTOMER_COMFORT = 'CustomerComfortCheck',
  ACTIVE_POWER_THRESHOLD = 'ActivePowerThreshold',
}

export enum SentinelParameterKey {
  rangeLowerBound = 'Range Lower Bound',
  rangeUpperBound = 'Range Upper Bound',
  threshold = 'Threshold',
}

export enum TargetType {
  SENSOR = 'SENSOR',
  EQUIPMENT = 'EQUIPMENT',
  SPACE = 'SPACE',
  DOOR = 'DOOR',
  GATEWAY = 'GATEWAY',
}

export enum ComparisonType {
  default = '',
  eq = 'eq',
  gt = 'gt',
  lt = 'lt',
  inner = 'inner',
  outer = 'outer',
}

export const comparisonTypesToSymbol = {
  default: '',
  [ComparisonType.eq]: '=',
  [ComparisonType.gt]: '>',
  [ComparisonType.lt]: '<',
  rg: '-',
  [ComparisonType.inner]: '-',
  [ComparisonType.outer]: '-',
};

export type SentinelTarget = {
  targetId: number;
  targetType: TargetType;
};

export type ParametersTemperature = {
  threshold?: number;
  comparisonType?: ComparisonType;
  rangeLowerBound?: number;
  rangeUpperBound?: number;
};

export type ParametersHumidity = {
  threshold?: number;
  comparisonType?: ComparisonType;
};

export type ParametersBatteryLevelCheck = {};

export type ParametersSwitch = {
  openClosed?: 'OPEN' | 'CLOSED';
};

export type Parameters = {
  threshold?: number;
  comparisonType?: ComparisonType;
  openClosed?: 'OPEN' | 'CLOSED';
  rangeLowerBound?: number;
  rangeUpperBound?: number;
};

export const defaultTemperatureParams: ParametersTemperature = {
  comparisonType: ComparisonType.eq,
  threshold: 0,
};

export const defaultDoorParameters: ParametersSwitch = {
  openClosed: 'OPEN',
};

export const defaultBatteryCheckParameters: ParametersBatteryLevelCheck = {};

export enum SentinelRecipientsChannel {
  USER_EMAIL = 'USER_EMAIL',
  USER_GROUP_EMAIL = 'USER_GROUP_EMAIL',
}

export type SentinelApiRecipient = {
  channel: SentinelRecipientsChannel;
  identifier: string;
};

export type SentinelRecipient = {
  channel: SentinelRecipientsChannel;
  identifier: string;
  title: string;
};

export enum SentinelPriorityLevel {
  NORMAL = 'NORMAL',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
  HIGHEST = 'HIGHEST',
}

export enum SentinelRecurType {
  NONE = 'NONE',
  UNTIL_RESOLVED = 'UNTIL_RESOLVED',
}

export type SentinelEscalation = {
  singleRunDelay?: SentinelInterval;
  singleRun: boolean;
  ix: number;
  recipients: SentinelApiRecipient[];
  priorityLevel: SentinelPriorityLevel;
  message?: string;
  name?: string;
  recurInterval?: SentinelInterval;
  recurType?: SentinelRecurType;
};

export enum SentinelScheduleWeekDay {
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
}

export enum SentinelScheduleType {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
}

export type ScheduleTimeRange = {
  begin: string;
  end: string;
};

export type SentinelSchedule = {
  scheduleType: SentinelScheduleType;
  timeRanges: ScheduleTimeRange[];
  weekDays: SentinelScheduleWeekDay[];
};

export enum IntervalUnit {
  DEVICEINTERVAL = 'DEVICEINTERVAL',
  MILLISECOND = 'MILLISECOND',
}

export type SentinelInterval = {
  unit: IntervalUnit;
  value: number;
};

export type Sentinel = {
  id: string;
  title: string;
  description: string;
  sentinelType: SentinelType;
  targets: SentinelTarget[];
  parameters: Parameters;
  escalations: SentinelEscalation[];
  schedules: SentinelSchedule[];
  reason?: string;
  mutedUntil?: string; // does not
  muteReason?: string;
  muted?: boolean;
  valid?: boolean;
  delay: SentinelInterval;
};

export type SentinelsApiFilter = {
  siteIds?: (number | string)[];
  equipmentIds?: (number | string)[];
  spaceIds?: (number | string)[];
  sensorIds?: (number | string)[];
};
