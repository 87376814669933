// import addDays from 'date-fns/addDays';
import subDays from 'date-fns/subDays';
import startOfDay from 'date-fns/startOfDay';
import format from 'date-fns/format';
import addMinutes from 'date-fns/addMinutes';
import addHours from 'date-fns/addHours';

const today = startOfDay(new Date());
const startOfTomorrow = today; // todo: addDays(today, 1);

export const notificationDateFilter = [
  {
    title: 'Today',
    from: format(today, 'yyyy-MM-dd'),
    to: format(startOfTomorrow, 'yyyy-MM-dd'),
  },
  {
    title: 'Yesterday',
    from: format(subDays(today, 1), 'yyyy-MM-dd'),
    to: format(subDays(startOfTomorrow, 1), 'yyyy-MM-dd'),
  },
  {
    title: 'Last 7 days',
    from: format(subDays(today, 7), 'yyyy-MM-dd'),
    to: format(startOfTomorrow, 'yyyy-MM-dd'),
  },
  {
    title: 'Last 30 days',
    from: format(subDays(today, 30), 'yyyy-MM-dd'),
    to: format(startOfTomorrow, 'yyyy-MM-dd'),
  },
];

export function createFilterOptions() {
  const today = startOfDay(new Date());
  const now = new Date();
  const startOfTomorrow = today;

  return [
    {
      title: '1 Hour',
      from: format(addMinutes(now, -60), 'yyyy-MM-dd'),
      to: format(now, 'yyyy-MM-dd'),
      minuteOffset: -60,
    },
    {
      title: '12 Hours',
      from: format(addHours(now, -12), 'yyyy-MM-dd'),
      to: format(now, 'yyyy-MM-dd'),
      minuteOffset: -60 * 12,
    },
    {
      title: 'Today',
      from: format(today, 'yyyy-MM-dd'),
      to: format(startOfTomorrow, 'yyyy-MM-dd'),
    },
    {
      title: 'Yesterday',
      from: format(subDays(today, 1), 'yyyy-MM-dd'),
      to: format(subDays(startOfTomorrow, 1), 'yyyy-MM-dd'),
    },
    {
      title: 'Last 7 days',
      from: format(subDays(today, 7), 'yyyy-MM-dd'),
      to: format(startOfTomorrow, 'yyyy-MM-dd'),
    },
    {
      title: 'Last 30 days',
      from: format(subDays(today, 30), 'yyyy-MM-dd'),
      to: format(startOfTomorrow, 'yyyy-MM-dd'),
    },
  ];
}

export default notificationDateFilter;
