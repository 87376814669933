import { fetchApi } from '../middleware/apiMiddleware';
import { Equipment, EquipmentApiFilter } from '../types/equipment';
import { SensorType } from '../types/sensors';

export enum Actions {
  EQUIPMENT_SUCCESS = '@@equipment/EQUIPMENT_SUCCESS',
  EQUIPMENT_LOADING = '@@equipment/EQUIPMENT_LOADING',
  EQUIPMENT_ERROR = '@@equipment/EQUIPMENT_ERROR',

  EQUIPMENT_BY_ID_SUCCESS = '@@equipment/EQUIPMENT_BY_ID_SUCCESS',
  EQUIPMENT_BY_ID_LOADING = '@@equipment/EQUIPMENT_BY_ID_LOADING',
  EQUIPMENT_BY_ID_ERROR = '@@equipment/EQUIPMENT_BY_ID_ERROR',
  EQUIPMENT_BY_SITES = '@@equipment/EQUIMENT_BY_SITES',
  EQUIPMENT_BY_SITES_LOADING = '@@equipment/EQUIPMENT_BY_SITES_LOADING',
  EQUIPMENT_BY_SITES_SUCCESS = '@@equipment/EQUIPMENT_BY_SITES_SUCCESS',
}

export const clearEquipment = () => ({
  type: Actions.EQUIPMENT_SUCCESS,
  payload: { equipment: [] },
});

const setEquipmentFilter = (filter?: EquipmentApiFilter): string => {
  const queryParams = new URLSearchParams();

  if (filter && filter.limit) {
    queryParams.set('limit', filter.limit.toString());
  }

  if (filter && filter.equipmentTypeIds) {
    queryParams.set('equipmentTypeIds', filter.equipmentTypeIds.join(','));
  }

  if (filter && filter.siteIds && filter.siteIds.length > 0) {
    queryParams.set('siteIds', filter.siteIds.join(','));
  }

  if (filter && filter.sensorTypes) {
    queryParams.set('sensorTypes', filter.sensorTypes.join(','));
  }

  queryParams.set('withPath', 'true');

  return `/api/v1/equipment?${queryParams.toString()}`;
};

export const fetchEquipment = (
  filter?: EquipmentApiFilter
): Promise<Equipment[]> =>
  fetchApi({
    endpoint: setEquipmentFilter(filter),
  });

export const getEquipment = (filter?: EquipmentApiFilter) => ({
  type: 'API_GET',
  path: setEquipmentFilter(filter),
  loading: Actions.EQUIPMENT_LOADING,
  success: Actions.EQUIPMENT_SUCCESS,
  error: Actions.EQUIPMENT_ERROR,
});

export const fetchEquipmentById = (equipmentId: number): Promise<Equipment> =>
  fetchApi({
    endpoint: `/api/v1/equipment/${equipmentId}/?withPath=true`,
  });

export const getEquipmentById = (equipmentId: number) => ({
  type: 'API_GET',
  path: `/api/v1/equipment/${equipmentId}/?withPath=true`,
  loading: Actions.EQUIPMENT_BY_ID_LOADING,
  success: Actions.EQUIPMENT_BY_ID_SUCCESS,
  error: Actions.EQUIPMENT_BY_ID_ERROR,
});

export const fetchEquipmentBySiteId = (siteId: number) =>
  fetchApi({
    endpoint: `/api/v1/equipment/site/${siteId.toString()}/`,
  });

export const fetchEquipmentBySiteIds = (siteIds: string[]) => async (
  dispatch: any
) => {
  dispatch({ type: Actions.EQUIPMENT_BY_SITES_LOADING });
  // @ts-ignore
  const equipmentBySites = await Promise.all(
    siteIds.map(async (siteId: string) => {
      try {
        // @ts-ignore
        const equipmentBySite = await fetchEquipmentBySiteId(siteId.toString());
        // @ts-ignore
        return Promise.resolve(equipmentBySite);

        // equipmentBySites.push(...equipmentBySite);
      } catch (err) {
        console.log(err);
        return err;
      }
    })
  );
  dispatch({
    type: Actions.EQUIPMENT_BY_SITES,
    // @ts-ignore
    payload: equipmentBySites.flat(),
  });
  dispatch({ type: Actions.EQUIPMENT_BY_SITES_SUCCESS });
};

export const getFetchEquipmentBySiteId = (siteId: number) => async (
  dispatch: any
) => {
  dispatch({ type: Actions.EQUIPMENT_LOADING });
  try {
    const equipment = await fetchEquipmentBySiteId(siteId);

    equipment.forEach((equip: any) => {
      if (equip._path && equip._path.length > 0 && equip.space === null) {
        /* eslint-disable */
        equip.space = equip._path[0];
        /* eslint-enable */
      }
    });

    dispatch({
      type: Actions.EQUIPMENT_SUCCESS,
      payload: { equipment, siteId },
    });
    return equipment;
  } catch (err) {
    dispatch({ type: Actions.EQUIPMENT_ERROR });
    return Promise.reject(new Error(err));
  }
};

export const fetchEquipmentBySiteIdAndSensorType = (
  siteId: number,
  sensorType: SensorType
): Promise<Equipment[]> =>
  fetchApi({
    endpoint: `/api/v1/equipment/site/${siteId.toString()}/sensors?sensorTypes=${sensorType}`,
  });

export const fetchEquipmentBySpaceId = (
  spaceId: number
): Promise<Equipment[]> =>
  fetchApi({
    endpoint: `/api/v1/equipment/space/${spaceId}`,
  });
