import { fetchApi } from '../middleware/apiMiddleware';
import { User, OrganizationRole } from '../types/user';

export enum Actions {
  USERS_SUCCESS = '@@user/USERS_SUCCESS',
  USERS_ERROR = '@@user/USERS_ERROR',
  USERS_LOADING = '@@user/USERS_LOADING',
  USER_INFO_SUCCESS = '@@user/USER_INFO_SUCCESS',
  USER_INFO_ERROR = '@@user/USER_INFO_ERROR',
  USER_INFO_LOADING = '@@user/USER_INFO_LOADING',

  USER_TYPE_SUCCESS = '@@user/USER_TYPE_SUCCESS',
  USER_TYPE_ERROR = '@@user/USER_TYPE_ERROR',

  USER_CHANGE_PASSWORD_LOADING = '@users/USER_CHANGE_PASSWORD_LOADING',
  USER_CHANGE_PASSWORD_ERROR = '@users/USER_CHANGE_PASSWORD_ERROR',
  USER_CHANGE_PASSWORD_SUCCESS = '@users/USER_CHANGE_PASSWORD_SUCCESS',
}

export const getUsers = () => ({
  type: 'API_GET',
  path: '/api/v1/users/',
  success: Actions.USERS_SUCCESS,
  error: Actions.USERS_ERROR,
  loading: Actions.USERS_LOADING,
});

export const updateUserPassword = (
  id: string,
  password: string,
  passwordVerify: string
) => ({
  type: 'API_PATCH',
  path: `/api/v1/users/${id}/password`,
  payload: {
    password,
    passwordVerify,
  },
  success: Actions.USER_CHANGE_PASSWORD_SUCCESS,
  error: Actions.USER_CHANGE_PASSWORD_ERROR,
  loading: Actions.USER_CHANGE_PASSWORD_LOADING,
});

export const getUserInfo = () => ({
  type: 'API_GET',
  path: '/api/v1/auth/user/?depth=0',
  success: Actions.USER_INFO_SUCCESS,
  error: Actions.USER_INFO_ERROR,
  loading: Actions.USER_INFO_LOADING,
});

export const getUserType = () => dispatch =>
  fetchApi({
    endpoint: `/api/v1/sites`,
  })
    .then(sites => {
      dispatch({
        type: Actions.USER_TYPE_SUCCESS,
        payload: {
          organizationRole:
            sites.length === 1
              ? OrganizationRole.SITE_MANAGER
              : OrganizationRole.REGIONAL_MANAGER,
          siteId: sites.length === 1 ? sites[0].id : null,
        },
      });
    })
    .catch(error => {
      console.error(error);
      dispatch({
        type: Actions.USER_TYPE_ERROR,
        payload: {
          error,
        },
      });
    });

export const fetchUsers = (): Promise<User[]> =>
  fetchApi({
    endpoint: '/api/v1/users/',
  });
