import { fetchApi } from '../middleware/apiMiddleware';
import { Service } from '../config';
import { Sentinel, SentinelsApiFilter } from '../types/sentinel';

const apiBase = '/api/v1/sentinels';

export enum Actions {
  SENTINELS_SUCCESS = '@@sentinels/SENTINELS_SUCCESS',
  DELETE_SUCCESS = '@@sentinels/DELETE_SUCCESS',
  MUTE_SUCCESS = '@@sentinels/MUTE_SUCCESS',
  RESUME_SUCCESS = '@@sentinels/RESUME_SUCCESS',

  GET_SENTINELS_SUCCESS = '@@sentinels/GET_SENTINELS_SUCCESS ',
  GET_SENTINELS_ERROR = '@@sentinels/GET_SENTINELS_ERROR ',
  GET_SENTINELS_LOADING = '@@sentinels/GET_SENTINELS_LOADING ',

  GET_SENTINELS_BY_SENSOR_ID_SUCCESS = '@@sentinels/GET_SENTINELS_BY_SENSOR_ID_SUCCESS',
  GET_SENTINELS_BY_SENSOR_ID_ERROR = '@@sentinels/GET_SENTINELS_BY_SENSOR_ID_ERROR',
  GET_SENTINELS_BY_SENSOR_ID_LOADING = '@@sentinels/GET_SENTINELS_BY_SENSOR_ID_LOADING',
}

export const setSentinelFilter = (filter?: SentinelsApiFilter) => {
  const queryParams = new URLSearchParams();

  if (filter && filter.equipmentIds) {
    queryParams.set('equipmentIds', filter.equipmentIds.toString());
  }

  if (filter && filter.siteIds) {
    queryParams.set('siteIds', filter.siteIds.join(','));
  }

  if (filter && filter.spaceIds) {
    queryParams.set('spaceIds', filter.spaceIds.join(','));
  }

  if (filter && filter.sensorIds) {
    queryParams.set('sensorIds', filter.sensorIds.join(','));
  }

  return queryParams.toString();
};

export const fetchSentinels = (filter?: SentinelsApiFilter) => {
  return fetchApi({
    endpoint: `${apiBase}/?${setSentinelFilter(filter)}`,
    method: 'GET',
    service: Service.sentinel,
  });
};

export const getSentinels = (filter?: SentinelsApiFilter) => ({
  type: 'API_GET',
  service: Service.sentinel,
  path: `${apiBase}?${setSentinelFilter(filter)}`,
  success: Actions.GET_SENTINELS_SUCCESS,
  error: Actions.GET_SENTINELS_ERROR,
  loading: Actions.GET_SENTINELS_LOADING,
});

export const fetchSentinelsCreate = (payload: Sentinel): Promise<any> =>
  fetchApi({
    endpoint: payload.id ? `${apiBase}/${payload.id}` : `${apiBase}`,
    method: payload.id ? 'PUT' : 'POST',
    service: Service.sentinel,
    payload,
  });

export const deleteSentinel = (sentinelId: string): Promise<any> =>
  fetchApi({
    endpoint: `${apiBase}/${sentinelId}`,
    method: 'DELETE',
    service: Service.sentinel,
  });

export const muteSentinel = (
  sentinelId: string,
  reason: string,
  mutedUntil: string | undefined
): Promise<any> => {
  return fetchApi({
    endpoint: `${apiBase}/${sentinelId}/mute`,
    method: 'POST',
    service: Service.sentinel,
    payload: {
      reason,
      mutedUntil,
    },
  });
};

export const resumeSentinel = (sentinelId: string): Promise<any> =>
  fetchApi({
    endpoint: `${apiBase}/${sentinelId}/mute`,
    method: 'DELETE',
    service: Service.sentinel,
  });

export const getSentinelById = (sentinelId: string): Promise<any> =>
  fetchApi({
    endpoint: `${apiBase}/${sentinelId}`,
    method: 'GET',
    service: Service.sentinel,
  });

export const getSentinelsBySensorId = (
  sensorId: string | number,
  filter?: SentinelsApiFilter
) => ({
  type: 'API_GET',
  service: Service.sentinel,
  path: `${apiBase}?${setSentinelFilter(filter)}`,
  success: { type: Actions.GET_SENTINELS_BY_SENSOR_ID_SUCCESS, sensorId },
  error: { type: Actions.GET_SENTINELS_BY_SENSOR_ID_ERROR, sensorId },
  loading: { type: Actions.GET_SENTINELS_BY_SENSOR_ID_LOADING, sensorId },
});
