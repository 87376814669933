export enum Service {
  iam = 'SERVICE_IAM',
  sentinel = 'SERVICE_SENTINELS',
  tsd = 'SERVICE_TSD_ROOT',
  cdn = 'SERVICE_CDN',
  analytics = 'SERVICE_ANALYTICS',
  reports = 'SERVICE_REPORTS',
}

export function getConfig() {
  const serviceUrls = {
    [Service.iam]:
      process.env.REACT_APP_SERVICE_IAM_ROOT ||
      'https://iam.staging.energybox.com',
    [Service.sentinel]:
      process.env.REACT_APP_SERVICE_SENTINEL_ROOT ||
      'https://sentinel.staging.energybox.com',
    [Service.tsd]:
      process.env.REACT_APP_SERVICE_TSD_ROOT ||
      'https://eb-api-staging.meso.net',
    [Service.cdn]:
      process.env.REACT_APP_SERVICE_CDN_ROOT ||
      'https://cdn.staging.energybox.com',
    [Service.analytics]:
      process.env.REACT_APP_SERVICE_ANALYTICS_ROOT ||
      'https://analytics.staging.energybox.com',
    [Service.reports]:
      process.env.REACT_APP_SERVICE_REPORTS_ROOT ||
      'https://reports.staging.energybox.com',
  };

  if (
    process.env.ENVIRONMENT === 'production' &&
    Object.values(serviceUrls).includes('')
  ) {
    throw new Error('env vars incomplete');
  }

  return { serviceUrls };
}

const config = getConfig();

export function getServiceUrl(service): string {
  switch (service) {
    case Service.sentinel:
      return config.serviceUrls.SERVICE_SENTINELS;
    case Service.tsd:
      return config.serviceUrls.SERVICE_TSD_ROOT;
    case Service.analytics:
      return config.serviceUrls.SERVICE_ANALYTICS;
    case Service.reports:
      return config.serviceUrls.SERVICE_REPORTS;
    default:
      return config.serviceUrls.SERVICE_IAM;
  }
}
